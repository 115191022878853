import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ProjectsService } from '@cosmos/angular/api';
import { StateService } from '@cosmos/angular/utils';
import { Project, User } from '@cosmos/shared';

@Component({
  selector: 'cosmos-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss']
})
export class SharedComponent implements OnInit {

  // user: User;
  // projectsWrite: Project[] = [];
  // projectsRead: Project[] = [];

  constructor() {}

  ngOnInit(): void {}

}
