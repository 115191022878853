export class Notification {
  _id?: string;
  type?: string;
  owner?: string;
  resource?: string;
  resourceId?;
  message?: string;
  read?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}
