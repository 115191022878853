<!-- {{curingPool | json}} -->

<div *ngIf="this.curingPool" class="row my-4">
    <div class="col-md-12">
        <mat-card appearance="outlined" >
            <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar">{{curingPool.name[0]}}</div>
                <mat-card-title class="mb-1">{{curingPool.name}}</mat-card-title>
                <mat-card-subtitle>{{ 'curingPool.subtitle' | translate }} - {{(curingPool.available ? 'curingPool.unlinked' : 'curingPool.linked') | translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-list>
                <mat-list-item>
                  <mat-icon matListItemIcon>schedule</mat-icon>
                  <div matListItemTitle>{{ curingPool.createdAt | date:'dd/MM/yyyy HH:mm:ss' }}</div>
                  <div matListItemLine>{{ "curingPool.created" | translate }}</div>
                </mat-list-item>
                <mat-list-item>
                  <mat-icon matListItemIcon>update</mat-icon>
                  <div matListItemTitle>{{ curingPool.updatedAt | date:'dd/MM/yyyy HH:mm:ss' }}</div>
                  <div matListItemLine>{{ "curingPool.updated" | translate }}</div>
                </mat-list-item>

                <mat-divider></mat-divider>
                
                <ng-container *ngIf="curingPool.available">
                    <mat-list-item>
                        <mat-icon matListItemIcon>description</mat-icon>
                        <div matListItemTitle>{{"curingPool.unactive" | translate}}</div>
                        <div matListItemLine>{{ "curingPool.card.unlinked" | translate }}</div>
                      </mat-list-item>
                </ng-container>
                <ng-container *ngIf="!curingPool.available">
                    <mat-list-item>
                        <mat-icon matListItemIcon>description</mat-icon>
                        <div matListItemTitle>{{ "curingPool.card.linkedTo" | translate}} {{curingPool.measurement.name}}</div>
                        <div matListItemLine>{{ "curingPool.card.linkedMeasurement" | translate }}</div>
                    </mat-list-item>
                </ng-container>
              </mat-list>
            </mat-card-content>
            <mat-card-actions>
                <button *ngIf="!curingPool.available && (user.roles.includes('admin') )" (click)="unlinkPool(curingPool)" mat-button [matTooltip]="'measurement.tooltip.unlink-pool' | translate" matTooltipClass="tooltip-red" matTooltipPosition="above" color="accent"><mat-icon inline="true">close</mat-icon><mat-icon inline="true">waves</mat-icon> {{ "measurement.unlink-pool" | translate }}</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>

<div *ngIf="this.curingPool" class="row my-4 pb-4">
    <div class="col-md-12">
        <mat-card appearance="outlined" >
            <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar"><mat-icon>rss_feed</mat-icon></div>
                <mat-card-title class="mb-1">{{"curingPool.telemetry-card.name" | translate}}</mat-card-title>
                <mat-card-subtitle>{{ 'curingPool.telemetry-card.subtitle' | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="warning" *ngIf="timeDiff > 2">
                    <span class="warning-bg"></span>
                    <div class="d-flex flex-row align-items-center px-2 py-2"><mat-icon>warning</mat-icon><p class="m-0 px-2">{{ 'curingPool.time-warning' | translate}}</p></div>
                </div>
                <mat-list>
                    <mat-list-item>
                    <mat-icon matListItemIcon>update</mat-icon>
                    <div matListItemTitle>{{ curingPool.lastseen | date:'dd/MM/yyyy HH:mm:ss' }}</div>
                    <div matListItemLine>{{ "curingPool.lastSeen" | translate }}</div>
                    </mat-list-item>
                </mat-list>

                <mat-divider></mat-divider>

                <div class="row">
                    <div class="col-md-6">
                        <mat-list>
                            <mat-list-item>
                                <mat-icon matListItemIcon [ngStyle]="{'color': curingPool.active ? 'green':'red'}">fiber_manual_record</mat-icon>
                                <div matListItemTitle>{{"curingPool.active" | translate}} {{(curingPool.active?"curingPool.enabled":"curingPool.disabled") | translate}}</div>
                                <div matListItemLine>{{ "curingPool.active-subtitle" | translate }}</div>
                            </mat-list-item>
                            <mat-list-item>
                                <mat-icon matListItemIcon [ngStyle]="{'color': curingPool.heater ? 'green':'red'}">fiber_manual_record</mat-icon>
                                <div matListItemTitle>{{"curingPool.heater" | translate}} {{(curingPool.heater?"curingPool.enabled":"curingPool.disabled") | translate}}</div>
                                <div matListItemLine>{{ "curingPool.heater-subtitle" | translate }}</div>
                            </mat-list-item>
                            <mat-list-item>
                                <mat-icon matListItemIcon [ngStyle]="{'color': curingPool.chiller ? 'green':'red'}">fiber_manual_record</mat-icon>
                                <div matListItemTitle>{{ "curingPool.chiller" | translate}} {{(curingPool.chiller?"curingPool.enabled":"curingPool.disabled") | translate}}</div>
                                <div matListItemLine>{{ "curingPool.chiller-subtitle" | translate }}</div>
                            </mat-list-item>
                        </mat-list> 
                    </div>

                    <div class="col-md-6">
                        <mat-list>
                            <mat-list-item>
                                <mat-icon matListItemIcon>device_thermostat</mat-icon>
                                <div matListItemTitle>{{curingPool.setpoint | number: '0.0-1'}} ºC</div>
                                <div matListItemLine>{{ "curingPool.setpoint" | translate }}</div>
                            </mat-list-item>
                            <mat-list-item>
                                <mat-icon matListItemIcon>device_thermostat</mat-icon>
                                <div matListItemTitle>{{curingPool.temp1 | number: '0.0-1'}} ºC</div>
                                <div matListItemLine>{{ "curingPool.temp1" | translate }}</div>
                            </mat-list-item>
                            <mat-list-item>
                                <mat-icon matListItemIcon>device_thermostat</mat-icon>
                                <div matListItemTitle>{{curingPool.temp2 | number: '0.0-1'}} ºC</div>
                                <div matListItemLine>{{ "curingPool.temp2" | translate }}</div>
                            </mat-list-item>
                        </mat-list> 
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="col-md-12" *ngIf="chartData">
        <!-- NORMAL CHART -->
        <mat-card  appearance="outlined"  class="my-2">
            <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar">
                    <mat-icon> insert_chart_outlined</mat-icon>
                </div>
                <mat-card-title class="mb-1">{{ "curingPool.chart.title" | translate }}</mat-card-title>
                <mat-card-subtitle>{{ "curingPool.chart.subtitle" | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <cosmos-pool-chart [data]="chartData"></cosmos-pool-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>
