export class Measurement {
  _id?: string;
  name?: string;
  description?: string;
  owner?: string;
  shared_w?: string[];
  shared_r?: string[];
  project?: any;
  stage?: any;
  concrete?: string;
  device?: string;
  active?: boolean;
  maturity?: number[];
  strength?: number[];
  time?: Date[];
  temperature?: number[];
  timeAll?: Date[];
  temperatureAll?: number[];
  createdAt?: Date;
  updatedAt?: Date;
  company?: any;
  report?: string;
  specifications?: number[];
  specifications_maturity?: number[];
  curingPoolData?: {temperature: number[], time: Date[]}[];
  curingPoolFinished?: boolean;
}
