
<!-- <button *ngIf="showButton" mat-raised-button color="primary" (click)="openDialog()"> {{ "projects.button.new" | translate }}</button> -->

<!-- PROJECT CARDS -->
<cosmos-project-card [projects]="projects"></cosmos-project-card>

<mat-divider></mat-divider>



<!--
<div *ngFor="let project of this.projects">
    <a  routerLink="/projects/{{ project._id }}"> {{project.name}}</a>
</div>
-->
