  <!-- K8 SELF HOSTED -->
  <div appDynamicScript>
    <div>
    window.chatwootSettings = {{ '{' }}"position":"right", "hideMessageBubble": true, "useBrowserLanguage": true, "type":"standard","launcherTitle":"Habla con nosotros", "darkMode": "auto"{{ '}' }};
    (function(d,t) {{ '{' }}
        var BASE_URL="https://support.cosmosengineering.es";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){{ '{' }}
        window.chatwootSDK.run({{ '{' }}
            websiteToken: 'vGwjGYH62fmeiZopBmYfYWXs',
            baseUrl: BASE_URL
        {{ '}' }})
        {{ '}' }}
    {{ '}' }})(document,"script");
</div>
</div>

<cosmos-navigation></cosmos-navigation>