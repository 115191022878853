import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ColumnDefinition } from '@cosmos/angular/ui';
import { Measurement } from '@cosmos/shared';
import { environment } from 'environments/environment';

@Component({
  selector: 'cosmos-active-measurements',
  templateUrl: './active-measurements.component.html',
  styleUrls: ['./active-measurements.component.scss']
})
export class ActiveMeasurementsComponent implements OnInit {

  measurements: Measurement[] = [];

  columns: ColumnDefinition[] = [
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'strength', label: 'table.labels.strength', visible: true, sortable: true, draggable: true, type: "numArray"},
    {name: 'maturity', label: 'table.labels.maturity', visible: true, sortable: true, draggable: true, type: "numArray"},
    {name: 'temperature', label: 'table.labels.temperature', visible: true, sortable: true, draggable: true, type: "numArray"},
    {name: "time", label: 'table.labels.updated-at', visible: true, sortable: true, draggable: true, type: "dateArray"},
    {name: 'project.name', label: 'table.labels.project', visible: true, sortable: true, draggable: true},
    {name: 'stage.name', label: 'table.labels.stage', visible: true, sortable: true, draggable: true},
    {name: 'device.name', label: 'table.labels.device', visible: true, sortable: true, draggable: true}
  ];


  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<Measurement[]>(`${environment.API_URL}/measurements/?active=true&populate_project=name&populate_stage=name&populate_device=name`, {observe: 'response'})
    // this.http.get<Measurement[]>(`${environment.API_URL}/measurements/usermeasurements/?active=true`, {observe: 'response'})
    .subscribe({
      next: (event: any) => {
        this.measurements = event.body;
      }
    });
  }

}
