<div class="flex">
  <mat-toolbar color="primary" >
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleNav()" class="d-flex align-items-center">
      <mat-icon class="py-0" aria-label="Side nav toggle icon">menu</mat-icon>
    </button>

    <h1 class="h5 my-0">{{ 'dashboard.title' | translate}}</h1>

    <span class="nav-spacer"></span>

    <!-- <button mat-icon-button (click)="changeLanguage('es')" [id]="'dashboard.id' | translate">
      ES
    </button>
    <button mat-icon-button (click)="changeLanguage('en')">
      EN
    </button> -->

    <!-- <button mat-icon-button (click)="logout()">
      <mat-icon>logout</mat-icon>
    </button> -->
    <!-- <cosmos-styles-dropdown></cosmos-styles-dropdown> -->
    <button mat-icon-button (click)="toggleChatwoot()"><mat-icon>live_help</mat-icon></button>
    <cosmos-notification-dropdown></cosmos-notification-dropdown>
    <cosmos-language-dropdown></cosmos-language-dropdown>
    <cosmos-user-dropdown></cosmos-user-dropdown>
  </mat-toolbar>
  <cosmos-sidenav [openNav]="isOpen"></cosmos-sidenav>
  <!-- <app-sidenav ></app-sidenav> -->
</div>
