import { Component, Input, OnInit } from '@angular/core';
import { ConversionService } from '@cosmos/angular/utils';
import { Device, LoraMetadata } from '@cosmos/shared';

@Component({
  selector: 'cosmos-device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.scss']
})
export class DeviceCardComponent implements OnInit {

  @Input() device: Device;
  @Input() showNavigation: boolean = false;

  constructor(
    private conversionService: ConversionService,
  ) { }

  ngOnInit(): void {}

  getTransmissionDetails(transmission: LoraMetadata) {
    return this.conversionService.getTransmissionInfo(transmission);
  }

  getBattery(battery: number) {
    return this.conversionService.getBatteryInfo(battery);
  }
}
