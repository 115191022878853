import { Component, OnInit } from '@angular/core';
import { AuthService, StorageService } from '@cosmos/angular/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cosmos-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  isOpen = false;

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    // private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if (this.storageService.getItem('navOpen')) this.isOpen = JSON.parse(this.storageService.getItem('navOpen'));
  }

  toggleNav(): void {
    this.isOpen = !this.isOpen;
    this.storageService.setItem('navOpen', this.isOpen);
    // this.storageService.setItem('groupDisplay','table');
  }

  toggleChatwoot(): void {
    window.$chatwoot.toggle();
  }

  // changeLanguage(lang: string): void {
  //   this.translate.use(lang);
  // }

  logout(): void {
    this.authService.signOut();
  }

}

declare global {
  interface Window {
      $chatwoot:any;
  }
}