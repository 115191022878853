import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthorizedGuard  {

  constructor(
    private router: Router,
    private stateService: StateService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const currentUser = this.stateService.getCurrentUser();

      if (currentUser.authorized) {
          // logged in so return true
          return true;
      }
      // not logged in so redirect to login page with the return url
      this.snackBar.open("Tu cuenta aún se está configurando. Recibirás un email cuando puedas acceder.","OK", {duration:5000})
      this.authService.purgeAuth();
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

}
