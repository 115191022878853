import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConcretesService, CuringPoolssService, DevicesService, MeasurementsService } from '@cosmos/angular/api';
import { StateService } from '@cosmos/angular/utils';
import { Concrete, CuringPool, Device, Measurement, Project, User } from '@cosmos/shared';
import { DialogComponent } from '../dialog/dialog.component';
import { ErrorService } from '@cosmos/angular/utils';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, FileUploadService } from '@cosmos/angular/ui';

@Component({
  selector: 'cosmos-measurement',
  templateUrl: './measurement.component.html',
  styleUrls: ['./measurement.component.scss']
})
export class MeasurementComponent implements OnInit {

  measurement: Measurement;
  concrete: Concrete;
  device: Device;
  project: Project;
  user: User;
  predictions: any;
  curingPool: CuringPool | null = null;
  chartData: any = null;

  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;


  constructor(
    private errorService: ErrorService,
    private translateService: TranslateService,
    private readonly measurementsService: MeasurementsService,
    private readonly concretesService: ConcretesService,
    private readonly devicesService: DevicesService,
    private filesService: FileUploadService,
    private stateService: StateService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    public finishDialog: MatDialog,
    private curingPoolService: CuringPoolssService,
  ) { }

  ngOnInit(): void {
    this.stateService.currentUser$.subscribe(x => {this.user = x;});

    const url = (this.router.url).split("/");//Gets the current url, using the last part for the object
    this.measurementsService.findById(url[url.length - 1], '/?populate_project').subscribe({
      next: (response) => {this.project = response.body.project; this.measurement = response.body},
      error: (e) => this.errorService.handleError(e),
      complete: () => {
        this.getData();
        if (this.measurement.curingPoolData.length > 0 && this.measurement.curingPoolFinished) {
          this.chartData = {
            measurement: {
              time: this.measurement.time,
              temperature: this.measurement.temperature
            },
            pool: {
              time: this.measurement.curingPoolData[0].time,
              temperature: this.measurement.curingPoolData[0].temperature
            }
          }
        } else {
          this.chartData = null;
        }
      },
    });

    this.measurementsService.getPredictions(url[url.length - 1]).subscribe({
      next: (response) => {this.predictions = response.body;},
      error: (e) => this.errorService.handleError(e),
      complete: () => {},
    });

    // CALCULATE PREDICTION FOR CONCRETE
    // if(this.project.premium) {
    //   const
    // }
  }

  getData() {
      this.concretesService.findById(this.measurement.concrete).subscribe({
        next: (event:any) => { this.concrete = event.body;}
      });
      this.devicesService.findById(this.measurement.device).subscribe({
        next: (event:any) => { this.device = event.body;}
      });
      this.curingPoolService.find('?measurement='+this.measurement._id).subscribe({
        next: (event: any) => { 
          if (event.body.length) this.curingPool = event.body[0];
          else this.curingPool = null;
        },
        error: (e) => this.errorService.handleError(e),
        complete: () => {
          if (this.curingPool?.time.length > 0) {
            this.chartData = {
              measurement: {
                time: this.measurement.time,
                temperature: this.measurement.temperature
              },
              pool: {
                time: this.curingPool.time,
                temperature: this.curingPool.temp
              }
            }
          } else {
            this.chartData = null;
          }
        },
      });

      //IN DEVELOPMENT
      this.calculateRelativeMaturity();
  }

  editMeasurement() {
    this.concretesService.find('/?project=' + this.project._id).subscribe({
      next: (response) =>{ this.openDialog(response.body)},
    })
  }

  finishMeasurement(id: string) {
    this.confirmDialog = this.finishDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.finish-measurement") + this.measurement.name;

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.measurementsService.finish(id).subscribe({
          next: (response) => this.measurement = response,
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData(),
        })
      }
    });
  }

  // CHARI
  linkToPool(id: string) {
    let curingPools;
    this.curingPoolService.find('?available=true').subscribe({
      next: (response) => {console.log(response); curingPools = response.body},
      error: (e) => this.errorService.handleError(e),
      complete: () => {
        console.log(curingPools);
        const type = "pool";
        // const measurement =  this.measurement;
        // const create = false;
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {type, curingPools}
        });

        dialogRef.afterClosed().subscribe(async result => {
          if (result !== undefined && result['curing-pool']){
            this.curingPoolService.link([result['curing-pool'], {measurement: this.measurement._id, project: this.measurement.project._id}]).subscribe({
              next: (response) => console.log(response),
              error: (e) => this.errorService.handleError(e),
              complete: () => this.getData(),
            })
            console.log(result);
          }
        });
      }
    })
  }

  unlinkPool(pool) {
    this.curingPoolService.unlink([pool._id, {}]).subscribe({
      next: (response) => console.log(response),
      error: (e) => this.errorService.handleError(e),
      complete: () => this.getData(),
    });
  }

  changeDevice(measurement: Measurement){
    let devices;
    this.devicesService.find('/?project=' + this.measurement.project._id + '&available=true').subscribe({
      next: (response) => devices = response.body,
      error: (e) => {
        if(e.error.message == "Devices not Found"){
        this.snackBar.open(this.translateService.instant('devices.not-found'),"OK",{duration: 5000});
        }
      },
      complete: () =>{
        const type = "changeDevice";
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {type, devices, measurement}
        });
        dialogRef.afterClosed().subscribe(async result => {
          if (result!== undefined) {
            this.changeDeviceSender(result);
          }
        })
      }
    });



  }

  changeDeviceSender(device: Device){
    this.measurementsService.changeDevice(this.measurement._id,device).subscribe({
      next: (response) => this.measurement = response.body,
      error: (e) => this.errorService.handleError(e),
      complete: () => this.getData(),
    })
  }

  openDialog(concretes: Concrete[]) {
    const type= "measurement";
    const measurement =  this.measurement;
    const create = false;

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {create, type, concretes, measurement}
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined){
        result.specifications = result.specifications;

        this.measurementsService.update([this.measurement._id,result]).subscribe({
          next: (response) => this.measurement = response.body,
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData(),
        })
      }
    })
  }

  generateReport() {
    this.measurementsService.generateReport(this.measurement._id).subscribe({
      next: (response) => {
        this.measurement.report = response.headers.get('Content-Disposition').split('=')[1]
        this.filesService.downloadFromBlob(response);
      },
      error: (e) => {
        this.snackBar.open(this.translateService.instant("measurement.download.error"),"OK")
      }
    })
  }

  downloadReport() {
    this.measurementsService.downloadReport(this.measurement._id).subscribe({
      next: (response) => {
        this.filesService.downloadFromBlob(response);
      },
      error: (e) => {
        this.snackBar.open(this.translateService.instant("measurement.download.error"),"OK")
      }
    })
  }

  downloadCSV() {
    this.measurementsService.downloadCSV('_id=' + this.measurement._id).subscribe({
      next: (response) => { this.filesService.downloadCSVFromBlob(response); },
      error: (e) => {
        this.snackBar.open(this.translateService.instant("measurement.download.error"),"OK")
      }
    })
  }

  openRescheduleDialog(measurement: Measurement) {
    const type = "reschedule"
    if (measurement.timeAll.length) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {type, measurement}
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result!== undefined && result.timestampStart >= 0 && result.timestampEnd >= 0) {
          this.reschedule(this.measurement._id, result.timestampStart, result.timestampEnd);
        }
      })
    } else {
      this.snackBar.open(this.translateService.instant("measurement.reschedule.no-data"),"OK")
    }
  }

  reschedule(id, timestampStart: number, timestampEnd: number) {
    console.log(id, timestampStart, timestampEnd);
    this.measurementsService.reschedule(id, timestampStart, timestampEnd).subscribe({
      next: (response) => {this.measurement = response.body; console.log(response.body)},
      error: (e) => this.errorService.handleError(e),
      complete: () => this.getData(),
    })
  }


  // IN DEVELOPMENT
  // Relative maturity
  relativeMaturity: number[];

  calculateRelativeMaturity() {
    // if(this.user.roles.indexOf('admin')>0) {
    if(true) {
      let relativeMaturityCalculation = [0]
      if (this.measurement.time.length == 1) {
        return;
      }

      const timeMillis = this.measurement.time.map( x => new Date(x).getTime() )

      timeMillis.slice(1).forEach((timestamp, i) => {
        const time_step = (timestamp - timeMillis[i]) / (86400000);
        const calculated_maturity  = (relativeMaturityCalculation[i] +  time_step * (this.measurement.temperature[i] - this.measurement.temperature[0] )).toFixed(2);
        relativeMaturityCalculation.push(Number(calculated_maturity));
      })
      this.measurement['relativeMaturity'] = relativeMaturityCalculation;
      this.relativeMaturity = relativeMaturityCalculation;
    }
  }
}
