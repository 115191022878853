import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, StateService } from '@cosmos/angular/utils';
import { TranslateService } from '@ngx-translate/core';
import { emailsBlocked } from './emails-block';

@Component({
  selector: 'cosmos-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm: UntypedFormGroup;
  email: string;
  password: string;
  loading = false;
  error = false;

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private stateService: StateService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
  ) {
    if (this.stateService.getCurrentUser()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      company_text: ['', Validators.required],
      phone: [''],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      terms: [false, Validators.required],
    });
  }

  get f(): any { return this.signupForm.controls; }

  async onSubmit() {
    this.loading = true;
    const domain = this.f.email.value.split('@')[1];
    const shouldBlock =  emailsBlocked.findIndex((element) => element == domain);

    console.log(this.f.terms.value);

    if(shouldBlock + 1) {
      this.loading = false;
      this.snackBar.open(this.translateService.instant('signup.generic-email'),"OK",{duration: 5000});
    } else {
      console.log('To backend');
      this.authService.signUpSim(this.f.email.value, this.f.password.value, this.f.name.value, this.f.surname.value, this.f.company_text.value, this.f.phone.value, this.f.terms.value).subscribe({
        next: (response) => {
          if(response) {
            this.snackBar.open(this.translateService.instant('signup.instructions'),"OK",{duration: 15000});
            const returnUrl =  '/';
            this.router.navigate([returnUrl]);
            this.loading = false;
          } else {
            this.loading = false;
          }
        },
        error: (e) => {
          this.snackBar.open(this.translateService.instant('signup.error'),"OK",{duration: 5000});
          this.loading = false;
          this.error = true;
        },
      });
    }
    
    
  }
}
