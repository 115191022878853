<link rel="stylesheet" href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css"
   integrity="sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ=="
   crossorigin=""/>
    <!-- Make sure you put this AFTER Leaflet's CSS -->
 <script src="https://unpkg.com/leaflet@1.8.0/dist/leaflet.js"
 integrity="sha512-BB3hKbKWOc9Ez/TAwyWxNXeoV9c1v6FIeYiBieIWkpLjauysF18NzgR1MBNBXf8/KABdlkX68nAhlwcDFLGPCQ=="
 crossorigin=""></script>

<mat-button-toggle-group #group="matButtonToggleGroup" multiple>
    <mat-button-toggle value="plano">{{ "projects.toggle_blueprint" | translate }}</mat-button-toggle>
    <mat-button-toggle value="map" (change)="onMapReady()">{{ "projects.toggle_map" | translate }}</mat-button-toggle>
    <mat-button-toggle *ngIf="project?.bim" value="bim">{{ "projects.toggle_bim" | translate }}</mat-button-toggle>
</mat-button-toggle-group>


<div class="row my-4">
    <div class="col-md">
        <mat-card  appearance="outlined"  *ngIf="this.project" class="my-2">
            <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar">{{project.name[0]}}</div>
                <mat-card-title class="mb-1">{{project.name}}</mat-card-title>
                <mat-card-subtitle>{{ "projects.card.description" | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-list>
                <mat-list-item>
                  <mat-icon matListItemIcon>schedule</mat-icon>
                  <div matListItemTitle>{{ project.createdAt | date:'dd/MM/yyyy HH:mm:ss' }}</div>
                  <div matListItemLine>{{ "projects.card.created-at" | translate }}</div>
                </mat-list-item>
                <mat-list-item>
                  <mat-icon matListItemIcon>update</mat-icon>
                  <div matListItemTitle>{{ project.updatedAt | date:'dd/MM/yyyy HH:mm:ss' }}</div>
                  <div matListItemLine>{{ "projects.card.updated-at" | translate }}</div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <!-- <div mat-subheader></div> -->
                <mat-list-item>
                  <mat-icon matListItemIcon>description</mat-icon>
                  <div matListItemTitle>{{project.description}}</div>
                  <div matListItemLine>{{ "projects.card.description-label" | translate }}</div>
                </mat-list-item>
              </mat-list>
                <!-- <p>
                    <mat-icon class="small-icon mr-1">schedule</mat-icon><b> </b></p>
                <p>
                    <mat-icon class="small-icon mr-1">update</mat-icon><b> </b></p>
                <p>
                    <mat-icon class="small-icon mr-1">description</mat-icon>{{project.description}}</p> -->
            </mat-card-content>
            <mat-card-actions>
                <button *ngIf="project?.owner === user?._id || (project?.shared_w.indexOf(user._id) + 1)" (click)="openDialog(false)" mat-button color="primary">{{ "button.edit" | translate }}</button>
                <button *ngIf="project?.owner === user?._id || (project?.shared_w.indexOf(user._id) + 1)" (click)="shareProject()" mat-button color="primary"> {{"projects.card.share" | translate}}</button>
                <button *ngIf="project?.owner === user?._id || (project?.shared_w.indexOf(user._id) + 1)" (click)="editReportsConfig()" mat-button color="primary">{{ "button.edit-reports-config" | translate }}</button>
                <button *ngIf="project?.owner === user?._id || (user.roles.includes('admin'))" (click)="editVisualizationConfig()" mat-button color="primary">{{ "button.edit-visualization-config" | translate }}</button>
                <button *ngIf="project?.owner === user?._id || (user.roles.includes('admin'))" (click)="editNotificationsConfig()" mat-button color="primary">{{ "button.edit-notifications-config" | translate }}</button>
            </mat-card-actions>
        </mat-card>
    </div>

    <!-- MUST use hidden, as if map is unloaded (with and ngIf, for example) it has to be reloaded before being used. This way it stays loaded if it exists even when hidden-->
    <div class="col-md-4" [hidden]="!group.value.includes('map')">
        <mat-card  appearance="outlined"  class="my-2">
            <mat-card-content *ngIf="empty">
                {{"projects.no_gateways" | translate}}
            </mat-card-content>
            <mat-card-content *ngIf="!empty">
                <div leaflet id="map"></div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class = "col-md-4"  *ngIf="group.value.includes('plano')" class="col align-self-center">
      <mat-card appearance="outlined" >
        <mat-card-content>
        <!-- <mat-card-header> -->
          <mat-card-title class="h5">
              {{ "projects.blueprint.title" | translate }}

              <button *ngIf="project?.owner === user?._id || (project?.shared_w.indexOf(user?._id) + 1)" mat-icon-button [matMenuTriggerFor]="menuImage" class="right_header">
                  <mat-icon>edit</mat-icon>
              </button>

              <button *ngIf="project?.owner === user?._id || (project?.shared_w.indexOf(user?._id) + 1)" mat-icon-button (click)="editFiles()" class="right_header">
                  <mat-icon>file_upload</mat-icon>
              </button>
          </mat-card-title>

              <mat-menu #menuImage>
                  <div *ngIf="files.length > 0">
                      <div *ngFor="let file of files">
                          <button mat-menu-item (click)="changeImage(file.filename)">
                              {{file.filename || "formfield.no_filename" | translate}}
                          </button>
                      </div>
                  </div>
                  <div *ngIf="files.length <= 0">
                      <button mat-menu-item>{{"projects.blueprint.empty" | translate}}</button>
                  </div>
              </mat-menu>
        <!-- </mat-card-header> -->
        
          <div *ngIf="image === null">{{ "projects.blueprint.no-file" | translate }}</div>

          <img *ngIf="blueprintType === 'image'" class="img-fluid" [src]="image">

          <div *ngIf="blueprintType === 'pdf'" >

                  <button mat-icon-button (click)="zoom = zoom + 0.1" >
                      <mat-icon>zoom_in</mat-icon>
                  </button>
                  <button mat-icon-button (click)="zoom = zoom - 0.1">
                      <mat-icon>zoom_out</mat-icon>
                  </button>

                  <pdf-viewer
                      [zoom]="zoom"
                      [src]="image"
                      [original-size]="false"
                      [autoresize]="true"
                      style="width: 100%; height: 600px;">
                  </pdf-viewer>
          </div>
        </mat-card-content>
      </mat-card>
  </div>

</div>


<div class="row my-4" *ngIf="group.value.includes('bim')">
    <div class="col-md">
        <mat-card  appearance="outlined"  class="my-2">
            <!-- <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar">V</div>
                <mat-card-title class="mb-1">Visor BIM</mat-card-title>
                <mat-card-subtitle>Por Autodesk Forge</mat-card-subtitle>
            </mat-card-header> -->
            <mat-card-content>
                <adsk-forge-viewer [viewerOptions]="viewerOptions" (onSelectionChanged)="selectionChanged($event)" (onGeometryLoaded)="initDataVisualization($event)"></adsk-forge-viewer>
                <!-- <adsk-forge-viewer [viewerOptions]="viewerOptions" (onSelectionChanged)="selectionChanged($event)" ></adsk-forge-viewer> -->
            </mat-card-content>
        </mat-card>
    </div>
</div>


<mat-divider></mat-divider>

<p class="h4 mt-4">{{ "stages.title" | translate }}</p>

<button mat-raised-button color="primary" *ngIf="project?.owner === user?._id || (project?.shared_w.indexOf(user?._id) + 1)" (click)="openDialog(true)">{{ "stages.button.new" | translate }}</button>

<cosmos-stage-card [stages]="stages"></cosmos-stage-card>



