import { Component, OnInit, Input } from '@angular/core';
import { StateService } from '@cosmos/angular/utils';
import { HttpEventType} from '@angular/common/http';
import { User } from '@cosmos/shared';
import { Project } from '@cosmos/shared';
import { ProjectsService } from '@cosmos/angular/api';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorService } from '@cosmos/angular/utils';

@Component({
  selector: 'cosmos-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  @Input() type: 'all' | 'owner' | 'shared_r' | 'shared_w' | 'shared' | 'edit' = 'owner';
  @Input() showButton = true;
  user: User;
  projects: Project[] = [];

  constructor(
    private errorService: ErrorService,
    private stateService: StateService,
    private projectsService: ProjectsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.stateService.currentUser$.subscribe(x => {this.user = x;});
    this.getData();
    }

  openDialog(): any{
    const type = "project";
    const create  = true;
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {create, type }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined){
        result = {name: result.name, description: result.description, owner: this.user._id, company: this.user.company }
        this.projectsService.create(result).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => { this.getData();}
        })
      }
    })
  }

  getData(): void {
    let query = '';
    switch (this.type) {
      case 'all':
        query = '';
        break;
      case 'owner':
        query = '?owner=' + this.user._id;
        break;
      case 'shared':
        query = '?$or=true&shared_r=' + this.user._id + '&shared_w=' + this.user._id;
        break;
      case 'shared_r':
        query = '?shared_r=' + this.user._id;
        break;
      case 'shared_w':
        query = '?shared_w=' + this.user._id;
        break;
      case 'edit':
        query = '?$or=true&owner=' + this.user._id + '&shared_w=' + this.user._id;
        break;

      default:
        break;
    }

    this.projectsService.find(query).subscribe({
      next: (event: any) => {
        if(event.type === HttpEventType.Response){
          this.projects = event.body;
        }
      },
      error:(e) => this.errorService.handleError(e),
    });
  }
  }
