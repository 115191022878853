import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, RoleGuard } from '@cosmos/angular/utils';
import { DashboardComponent } from './dashboard.component';
import { LandingComponent } from './landing/landing.component';
import { UserComponent } from './pages/user/user.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SharedComponent } from './pages/shared/shared.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SupportComponent } from './pages/support/support.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { BreadcrumbsResolverService } from '@cosmos/angular/ui';
//--- Variable path components
import { ProjectComponent } from './pages/project/project.component';
import { StageComponent } from './pages/stage/stage.component';
import { MeasurementComponent } from './pages/measurement/measurement.component';
import { DeviceComponent } from './pages/device/device.component';
import { GatewayComponent } from './pages/gateway/gateway.component';
import { CuringPoolComponent } from './pages/curing-pool/curing-pool.component';
import { CuringPoolDetailComponent } from './pages/curing-pool/curing-pool-detail/curing-pool-detail.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard, RoleGuard], data: {breadcrumb: "home", role: "user"}, children: [
    { path: '', component: LandingComponent,canActivate: [AuthGuard], data: {breadcrumb: ""}},
    { path: 'notifications', component: NotificationsComponent, data: {breadcrumb: "breadcrumbs.notifications"}},
    { path: 'user', component: UserComponent,canActivate: [AuthGuard], data: {breadcrumb: "breadcrumbs.user"}},
    { path: 'settings', component: SettingsComponent, data: {breadcrumb: "breadcrumbs.settings"}},
    { path: 'shared', component: SharedComponent, data: {breadcrumb: "breadcrumbs.shared"}},
    { path: 'reports', component: ReportsComponent, data: {breadcrumb: "breadcrumbs.reports"}},
    { path: 'support', component: SupportComponent, data: {breadcrumb: "breadcrumbs.support"}},
    { path: 'devices', data: {breadcrumb: "breadcrumbs.devices"}, children: [
        { path : '', component: DevicesComponent, data: {breadcrumb: ""}},
          //--------- Variable path component // Could be a more intuitive solution? to  mulitple?
        { path: 'd/' + ':device', component: DeviceComponent, data: {breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "device"}, resolve: { data: BreadcrumbsResolverService}},
        { path: 'g/' + ':gateway', component: GatewayComponent, data: {breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "gateway"}, resolve: { data: BreadcrumbsResolverService}},
      ]
    },

    // UNCOMMENT WHEN LAB IS CREATED
    // { path: 'lab', data: {breadcrumb: "breadcrumbs.lab"}, children: [
    //     { path : '', component: CuringPoolComponent, data: {breadcrumb: ""}},
    //     { path: 'curing-pools', component: CuringPoolComponent, data: {breadcrumb: "breadcrumbs.curing-pools"}},
    //     { path: 'curing-pools/' + ':curingpool', component: CuringPoolDetailComponent, data: {breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "curingpool"}, resolve: { data: BreadcrumbsResolverService}},
    // -----------------------------
    // DELETE WHEN LAB IS CREATED
    { path: 'lab/curing-pools', data: {breadcrumb: "breadcrumbs.lab"}, children: [
        { path: '', component: CuringPoolComponent, data: {breadcrumb: "breadcrumbs.curing-pools"}},
        { path: ':curingpool', component: CuringPoolDetailComponent, data: {breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "curingpool"}, resolve: { data: BreadcrumbsResolverService}},
    // --------------------------
        
      ]
    },
    //--------- Variable path components IMPORTANT, PATH AND RESOLVE MUST BE EQUAL, but path has : at start
    { path: 'projects', data: {breadcrumb: "projects.breadcrumb"}, children: [
        { path : '', component: ProjectsComponent, data: {breadcrumb: ""}},
        { path: ':project', data: { breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "project"},resolve: { data: BreadcrumbsResolverService }, children: [
        // { path: ':project', data: { breadcrumb: (data: any) => [ "breadcrumbs.project", data.data[1].body.name], resolve: "project"},resolve: { data: BreadcrumbsResolverService }, children: [
          { path: '',  component: ProjectComponent,data: {breadcrumb: ""}},
          { path: ':stage' , data: { breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "stage"},resolve: { data: BreadcrumbsResolverService },  children: [
              { path: '',  component: StageComponent, data: {breadcrumb: ""}},
              { path: ':measurement', component: MeasurementComponent, data: { breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "measurement"},resolve: { data: BreadcrumbsResolverService}},
            ]
          }
        ]
      }]
    }
    ]
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule,
  ]
})
export class DashboardRoutingModule { }
