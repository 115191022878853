<cosmos-data-table *ngIf="notifications.length > 0"
  [tableData]="notifications"
  [tableColumns]="columns"
  [isFilterable]="true"
  [isPageable]="true"
  [defaultPageSize]="10"
  [toggleColumnsVisibility]="true"
  [rowActions]="actions"
  (rowAction)="rowAction($event)">
</cosmos-data-table>

<div class="row mb-4" *ngIf="notifications.length === 0">
    <div class="col-md">
        {{'landing.warnings.empty' | translate}}
      </div>
</div>
