import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cosmos-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  email: string;

  constructor(
    private translateService: TranslateService,
    private stateService: StateService,
    private router: Router,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private errorService: ErrorService,
  ) {
    if (this.stateService.getCurrentUser()) {
      this.router.navigate(['/']);
  }
   }

  ngOnInit(): void {
  }

  onClick(){
    //this.snackBar.open("Recovery email sent, check your inbox.","OK");
    this.http.post(`${environment.API_URL}/recoveryemail/`, {email: this.email},{observe: 'response'}).subscribe({
      next: () => {
        this.snackBar.open(this.translateService.instant('recovery.success'),"OK");
      },
      error: (e) => {this.snackBar.open(this.translateService.instant('recovery.error'),"OK");},
    })
  }

}
