import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UsersService } from '@cosmos/angular/api';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { User } from '@cosmos/shared';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionDefinition, ColumnDefinition, FileUploadService } from '@cosmos/angular/ui';
import { FileE } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cosmos-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  user: User;
  changeName: string;
  changeSurname: string;
  changePhone: string;
  files: FileE[] = [];

  actions: ActionDefinition[] = [
    {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true, role: "user"},
  ];

  columns: ColumnDefinition[] = [
    {name: 'filename', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'company.name', label: 'table.labels.company', visible: true, sortable: true, draggable: true},
    {name: 'filetype', label: 'table.labels.filetype', visible: true, sortable: true, draggable: true},
    {name: 'service', label: 'table.labels.service', visible: true, sortable: true, draggable: true},
    {name: 'serviceId.name', label: 'table.labels.service_id', visible: true, sortable: true, draggable: true},
  ];

  constructor(
    private translateService: TranslateService,
    private errorService: ErrorService,
    private fileUploadService: FileUploadService,
    private stateService: StateService,
    private usersService: UsersService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    ) {
    this.stateService.currentUser$.subscribe(x => {this.user = x;});
  }

  ngOnInit(): void {
    this.changeName = this.user.name;
    this.changeSurname = this.user.surname;
    this.getData();
  }

  getData() {
    this.fileUploadService.find('?owner=' + this.user._id + "&service=Project&populate_company=name&populate_serviceId=name").subscribe({
      next: (response) => this.files = response.body,
      error: (e) => this.errorService.handleError(e.error),
    })
  }

  updateUser(): void{
    this.user.name = this.changeName;
    this.user.surname = this.changeSurname;
    this.user.phone = this.changePhone;
    this.usersService.update([this.user._id, this.user]).subscribe({
      error: (e) => this.errorService.handleError(e),
    });
  }

  resetPassword(): void{
    this.http.post(`${environment.API_URL}/recoveryemail/`, {email: this.user.email},{observe: 'response'}).subscribe({
      next: () => {
        this.snackBar.open(this.translateService.instant('recovery.success'),"OK");
      },
      error: (e) => {this.snackBar.open(this.translateService.instant('recovery.error'),"OK"); this.errorService.handleError(e);}
    });
  }

  rowAction(event: any): void {
    if(event.action === 'delete') {
      this.deleteFile(event.row);
    }
  }

  deleteFile(file: FileE): void {
    this.fileUploadService.delete(file.path,file._id,file.filename).subscribe({
      error: (e) => this.errorService.handleError(e.error),
      complete: () => this.getData()
    })
  }

}
