<div class="row">
  <div class="col-sm-3" *ngFor="let gateway of userGateways; let i = index">
    <mat-card appearance="outlined" class="mx-1 my-1">
      <!-- HEADER -->
      <mat-card-header>
        <mat-card-title>
          {{gateway.name}}
        </mat-card-title>
        <mat-card-subtitle>
          {{'landing.gateway-status.subtitle' | translate}}
        </mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <mat-list>
          <!-- LAST SEEN -->
          <mat-list-item>
            <mat-icon matListItemIcon>schedule</mat-icon>
            <div matListItemTitle>
              <span *ngIf="gateway.chirpstackMetadata.lastSeenAt !== null">
                {{gateway.chirpstackMetadata.lastSeenAt | date:'dd/MM/yyyy HH:mm'}}
              </span>
              <span *ngIf="gateway.chirpstackMetadata.lastSeenAt === null">
                {{"landing.gateway-status.never" | translate}}
              </span>
            </div>
            <div matListItemLine>
              {{"landing.gateway-status.last_seen" | translate}}
            </div>
          </mat-list-item>
          <!-- CONNECTION STATUS -->
          <mat-list-item>
            <mat-icon matListItemIcon>rss_feed</mat-icon>
            <div matListItemTitle>
              <span *ngIf="timeComparison[i] <= 360000" class="green"><!--6min-->
                ● {{"landing.gateway-status.good" | translate}}
              </span>
              <span *ngIf="timeComparison[i] > 360000" class="red"><!--6min-->
                ● {{"landing.gateway-status.bad" | translate}}
              </span>
            </div>
            <div matListItemLine>{{"landing.gateway-status.connection" | translate}}</div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
      <!-- RESTART THROUGH VPN BUTTON -->
      <mat-card-actions>
        <!-- NOT YET AVAILABLE -->
        <!-- <button mat-raised-button color="primary" routerLink="devices/g/{{gateway._id}}">{{"button.details" | translate}}</button> -->

        <!-- <button *ngIf="timeComparison[i] > 360000" mat-stroked-button color="primary" >{{"landing.gateway-status.restart-gw" | translate}}</button> -->
      </mat-card-actions>
    </mat-card>
  </div>
</div>
