import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UsersService } from '@cosmos/angular/api';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { User } from '@cosmos/shared';

@Component({
  selector: 'cosmos-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {

  user: User;

  userNotifs = this.formBuilder.group({
    error: false,
    warning: false,
    information: false
  });

  constructor(
    private errorService: ErrorService,
    private formBuilder: UntypedFormBuilder,
    private stateService: StateService,
    private usersService: UsersService,
    ) {
    this.stateService.currentUser$.subscribe(x => {this.user = x;});
  }

  ngOnInit(): void {

    this.userNotifs.setValue({
      error:  this.user.notification.error,
      warning: this.user.notification.warning,
      information: this.user.notification.information
    });
  }

  saveNotifs(){
    this.user.notification = this.userNotifs.value;
    this.usersService.update([this.user._id, this.user]).subscribe({
      next: (response) => {this.stateService.setCurrentUser(response.body)},
      error: (e) => this.errorService.handleError(e),
    });
  }

}
