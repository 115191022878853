import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';


@Component({
  selector: 'cosmos-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss']
})
export class ValidateComponent implements OnInit {

  validating = true;
  validateText = '';
  token: string;

  constructor(
    private translateService: TranslateService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
  ) {
    console.log(this.activatedRoute.snapshot);
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
    })

    const body = {token: this.token};


    this.validateText = this.translateService.instant('signup.validating-token');
    this.http.post(`${environment.API_URL}/validate-email`, body, {observe: 'response'}).subscribe({
      next: () => {
      this.validateText = this.translateService.instant('signup.account-active');
      this.validating = false;
      },
      error: (e) => { 
        this.validateText = this.translateService.instant('signup.validate-error');
      }
    })

  }

  ngOnInit(): void {
  }

}
