export class CuringPool {
  _id?: string;
  name?: string;
  eui?: string;
  available?: boolean;
  serviceAccount?: any;
  measurement?: any;
  project?: any;
  company?: any;
  updatedAt?: Date;
  createdAt?: Date;
  lastseen?: Date;
  heater?: boolean;
  chiller?: boolean;
  temp1?: number;
  temp2?: number;
  time?: any[];
  temp?: number[];
}
