<h1 mat-dialog-title>{{title}}</h1>

<mat-dialog-content>
  <p>{{"projects.visualizationConfig.intro" | translate }}</p>

  <section class="" [formGroup]="visualizationConfig">
    <ul>
    <li formGroupName="maturity">Madurez:
      <mat-checkbox formControlName="app">App</mat-checkbox>
      <mat-checkbox formControlName="report">Report</mat-checkbox>
    </li>
    <li formGroupName="relativeMaturity">Madurez relativa (experimental):
      <mat-checkbox formControlName="app">App</mat-checkbox>
      <mat-checkbox formControlName="report">Report</mat-checkbox>
    </li>
    <li formGroupName="maturityThreshold">Requisitos madurez:
      <mat-checkbox formControlName="app">App</mat-checkbox>
      <mat-checkbox formControlName="report">Report</mat-checkbox>
    </li>
  </ul>
  </section>

  <div class="form-row justify-content-center">
      <button mat-raised-button color="primary" (click)="saveForm()">{{ ('button.save') | translate}}</button>
      <!-- <button mat-icon-button color="primary" (click)="saveForm()" class="right_header"><mat-icon>close</mat-icon></button> -->
  </div>

</mat-dialog-content>
