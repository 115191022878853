export class Stage {
  _id?: string;
  name?: string;
  description?: string;
  owner?: string;
  shared_w?: string[];
  shared_r?: string[];
  project?: any;
  company?: any;
  createdAt?: Date;
  updatedAt?: Date;
  number_measurements: number;
}
