<p class="h4 mb-0">{{"projects.shared_title" | translate}}</p>

<!-- PROJECT CARDS -->
<!-- <div> -->
<!-- <p>Write permissions</p> -->
<!-- <cosmos-project-card [projects]="projectsWrite"></cosmos-project-card> -->
<!-- </div> -->
<cosmos-projects [type]="'shared'" [showButton]="false"></cosmos-projects>

<!-- <div>
  <p>Read permissions</p>
  <cosmos-project-card [projects]="projectsRead"></cosmos-project-card>
</div>

<mat-divider></mat-divider> -->
