<!-- <p>landing works!</p> -->

<!-- CONEXION RED GATEWAYS -->
<!-- Tarjetas de las gateways en los proyectos asignados indicando Last Seen -->
<div class="row mt-6">
  <div class="col-md h3">
    <p>{{ 'landing.gateway-status.title' | translate }}</p>
  </div>
</div>

<cosmos-gateway-status></cosmos-gateway-status>

<mat-divider></mat-divider>
<!-- NOTIFICACIONES IMPORTANTES -->
<!-- Obtiene todas aquellas notificaciones de prioridad maxima y que no esten leidas -->
<div class="row mt-6">
  <div class="col-md h3">
    <p>{{ 'landing.warnings.title' | translate }}</p>
  </div>
</div>

<cosmos-warnings></cosmos-warnings>

<mat-divider></mat-divider>
<!-- RESUMEN MEDIDAS ACTIVAS -->
<!-- Tabla con las mediads activas e información clave sobre ellas -->
<div class="row mt-6">
  <div class="col-md h3">
    <p>{{ 'landing.active-measurements.title' | translate }}</p>
  </div>
</div>

<cosmos-active-measurements></cosmos-active-measurements>

<mat-divider></mat-divider>
<div class="row mt-6">
  <div class="col-md h3">
    <p>{{'landing.project' | translate}}</p>
  </div>
</div>

<cosmos-projects [type]="'all'" [showButton]="true"></cosmos-projects>

<div class="row pt-4"></div>
