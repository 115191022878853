import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormFieldBase } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'cosmos-visualization-config-dialog',
  templateUrl: './project-visualization-dialog.component.html',
  styleUrls: ['./project-visualization-dialog.component.scss']
})
export class ProjectVisualizationDialogComponent implements OnInit {

  public save = true;
  concretes: any;
  devices: any;
  title = '';
  fields: Observable<FormFieldBase<string>[]>;
  visualizationConfig;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.title = this.translateService.instant('dialog.title.visualizationConfig');

    this.visualizationConfig = this.formBuilder.group({
      maturity: this.formBuilder.group({
        app: this.data.configVisualization?.maturity?.app, 
        report: this.data.configVisualization?.maturity?.report
      }),
      relativeMaturity: this.formBuilder.group({
        app: this.data.configVisualization?.relativeMaturity?.app, 
        report: this.data.configVisualization?.relativeMaturity?.report
      }),
      maturityThreshold: this.formBuilder.group({
        app: this.data.configVisualization?.maturityThreshold?.app, 
        report: this.data.configVisualization?.maturityThreshold?.report
      })
    });
  }

  saveForm(){
    this.dialogRef.close(this.visualizationConfig.value);
  }

}
