import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { NotificationsService } from '@cosmos/angular/api';
import { StateService } from '@cosmos/angular/utils';

@Component({
  selector: 'cosmos-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private notificationService: NotificationsService,
    private stateService: StateService,
    ) { }

  ngOnInit(): void {
    this.notificationService.setNotifications();
  }

  ngAfterViewInit(): void {
    // console.log('chatwoot ready event');
    const user = this.stateService.getCurrentUser();
    // console.log(user);
    window.addEventListener("chatwoot:ready", function () {
      window.$chatwoot.setUser(user._id, {
        email: user.email,
        name: user.name + ' ' + user.surname,
        phone_number: user.phone,
      });
    });
  }

  ngOnDestroy(): void {
    // document.getElementsByClassName("woot-widget-holder")[0]?.remove();
    // document.getElementsByClassName("woot--bubble-holder")[0]?.remove();
  }



}

declare global {
  interface Window {
      $chatwoot:any;
  }
}