<mat-button-toggle-group *ngIf="projects.length > 0" class="right_header" #group="matButtonToggleGroup" (change)="updateStorage(group.value)" value="{{currentGroup}}">
    <mat-button-toggle value="table" aria-label="Table">
        <mat-icon>list</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="cards" aria-label="Cards">
        <mat-icon>apps</mat-icon>
        </mat-button-toggle>
</mat-button-toggle-group>

<div class="row mb-4" *ngIf="projects.length > 0 && currentGroup === 'cards'">
    <div class="col-md-4" *ngFor="let project of projects">
        <mat-card  appearance="outlined"  class="my-2">
            <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar">{{project.name[0]}}</div>
                <mat-card-title class="mb-1">{{project.name}}</mat-card-title>
                <mat-card-subtitle>{{ "projects.card.description" | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-list>
                <mat-list-item>
                  <mat-icon matListItemIcon>square_foot</mat-icon>
                  <div matListItemTitle>{{project.number_measurements?project.number_measurements:'N/A'}}</div>
                  <div matListItemLine>{{ "projects.card.number-measurements" | translate }}</div>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button color="primary" routerLink="/projects/{{ project._id }}">{{ "button.open" | translate }}</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>

<cosmos-data-table
    *ngIf="projects.length > 0 && currentGroup === 'table'"
    [isLinked]="'project'"
    [tableData]="projects"
    [tableColumns]="columns"
    [isFilterable]="true"
    [toggleColumnsVisibility]="true"
    (rowAction)="rowAction($event)">
</cosmos-data-table>

<div class="row mb-4" *ngIf="projects.length === 0">
    <div class="col-md">
      {{ "projects.not-found" | translate }}
    </div>
</div>
