import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasswordRecoveryComponent } from 'libs/angular/ui/src/lib/password-recovery/password-recovery.component';
import { PasswordResetComponent } from 'libs/angular/ui/src/lib/password-reset/password-reset.component';

import { LoginComponent } from '../../../../libs/angular/ui/src/lib/login/login.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { ResetGuard } from '@cosmos/angular/utils';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'recovery', component: PasswordRecoveryComponent},
  { path: 'reset',canActivate: [ResetGuard], component: PasswordResetComponent},
  { path: '', loadChildren: () => DashboardModule },
  { path: '**', redirectTo: '' },
  
];

@NgModule({
  imports: [ RouterModule.forRoot(routes)],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
