import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DevicesService } from '@cosmos/angular/api';
import { ErrorService } from '@cosmos/angular/utils';
import { Device } from '@cosmos/shared';

@Component({
  selector: 'cosmos-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  device: Device;

  constructor(
    private readonly router: Router,
    private readonly devicesService: DevicesService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    // this.activatedRoute.data.subscribe(data =>{
    //   this.device = data.data[1].body;
    // });
    const url = (this.router.url).split("/");

    this.devicesService.findById(url[url.length-1], '?populate_measurement=name,stage&populate_project=name').subscribe({
      next: (response) => {console.log(response); this.device = response.body},
      error: (e) => this.errorService.handleError(e),
      complete: () => {}
    });
  }

}
