import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'cosmos-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor() {
  }
  title = 'f-user';
}
