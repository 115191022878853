import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { catchError, map, Observable } from 'rxjs';
import { ErrorService } from '../services/error.service';

@Injectable({
  providedIn: 'root'
})
export class ResetGuard  {

  constructor(
    private router: Router,
    private http: HttpClient,
    private errorService: ErrorService,
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = route.queryParamMap.get('token');
    return this.http.post(`${environment.API_URL}/checktoken`, {token: token}, {observe: 'response'})
      .pipe(
        map((data: any) => {
          if(data.body ==  200) {
            return true;
          } else {
            this.router.navigate(['/']);
            return false;
          }
        }),
        catchError( (error) => this.errorService.handleError(error))
      );
  }
}
