import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormFieldBase } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'cosmos-notifications-config-dialog',
  templateUrl: './project-notifications-dialog.component.html',
  styleUrls: ['./project-notifications-dialog.component.scss']
})
export class ProjectNotificationsDialogComponent implements OnInit {

  public save = true;
  title = '';
  fields: Observable<FormFieldBase<string>[]>;
  notificationsConfig;
  chipValues: string[] = [];
  inputControl: UntypedFormControl = new UntypedFormControl;


  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.title = this.translateService.instant('dialog.title.notificationsConfig');

    this.chipValues = this.data.configNotifications?.emails?this.data.configNotifications.emails:[];
    console.log(this.data);
    this.notificationsConfig = this.formBuilder.group({
      emails: new FormControl(this.data.configNotifications?.emails),
      strengthRequirements: this.formBuilder.group({
        app: this.data.configNotifications?.strengthRequirements?.app, 
        email: this.data.configNotifications?.strengthRequirements?.email
      })
    });
  }

  chipInputRemove(value): void {
    const index = this.chipValues.indexOf(value);

    if (index >= 0) {
      this.chipValues.splice(index, 1);
      this.notificationsConfig.controls['emails'].setValue(this.chipValues);
    }
  }

  add($event) {
    if(this.inputControl.valid) {
      this.chipValues.push($event.value);
      this.notificationsConfig.controls['emails'].setValue(this.chipValues);
      console.log($event.value);
    } else {
      this.snackBar.open(this.translateService.instant("projects.notificationsConfig.email-not-valid"), "OK", {duration: 2000});
    }
    
    $event.chipInput!.clear();
  }

  saveForm(){
    this.dialogRef.close(this.notificationsConfig.value);
  }

}
