<mat-card  appearance="outlined"  class="my-2">
    <mat-card-header>
        <div mat-card-avatar class="text-center card-avatar">{{gateway.name[0]}}</div>
        <mat-card-title class="mb-1">{{gateway.name}}</mat-card-title>
        <mat-card-subtitle>{{"cards.gateway.subtitle" | translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-list>
        <div mat-subheader>{{"cards.gateway.properties-section" | translate}}</div>
        <mat-list-item>
          <mat-icon matListItemIcon>qr_code_2</mat-icon>
          <div matListItemTitle>{{gateway.mac}}</div>
          <div matListItemLine> ID </div>
        </mat-list-item>

        <mat-list-item>
          <mat-icon matListItemIcon>folder_open</mat-icon>
          <div matListItemTitle>{{gateway.project?.name?gateway.project.name:'-'}}</div>
          <div matListItemLine>{{"cards.gateway.project" | translate}}</div>
        </mat-list-item>
      </mat-list>
      
      <mat-expansion-panel *ngIf="gateway.transmission.length > 1 && details">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="mx-2"
                [attr.style]="'color:' + getTransmissionDetails(gateway.transmission[gateway.transmission.length -1])[0]">
                  {{ getTransmissionDetails(gateway.transmission[gateway.transmission.length -1])[1] }}
                </mat-icon>
                {{getTransmissionDetails(gateway.transmission[gateway.transmission.length -1])[2]}}
            </mat-panel-title>
            <mat-panel-description>
              Señal
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div *ngFor="let transmission of gateway.transmission.slice().reverse()">
            <p class="py-2"
              [matTooltip]="'RSSI: ' + transmission.rssi + ' - SNR: ' + transmission.snr"
              matTooltipPosition="right">
                {{transmission.timestamp | date:'dd/MM/yyyy HH:mm:ss'}} - {{getTransmissionDetails(transmission)[2]}}. Data: {{transmission.data.temperature}} ºC
                <mat-divider class="my-2"></mat-divider>
            </p>
            
          </div>
        </mat-expansion-panel>
    </mat-card-content>
    <mat-card-actions *ngIf="showNavigation">
      <button mat-raised-button color="primary" routerLink="g/{{gateway._id}}">{{"button.details" | translate}}</button>
      <!-- <button *ngIf="!gateway.available" mat-button color="primary">{{"cards.gateway.subtitle" | translate}}</button> -->
    </mat-card-actions>
</mat-card>

