import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@cosmos/angular/api';
import { Notification } from '@cosmos/shared';
import { ColumnDefinition, ActionDefinition } from '@cosmos/angular/ui';
import { ErrorService } from '@cosmos/angular/utils';
import { StateService } from '@cosmos/angular/utils';

@Component({
  selector: 'cosmos-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: Notification[];

  actions: ActionDefinition[] = [
    {name: 'markAsRead', label: 'table.actions.read', icon: 'mark_email_unread', iconAction: 'drafts', sticky: true},
    {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true},
  ];

  columns: ColumnDefinition[] = [
    {name: 'type', label: 'table.labels.type', visible: true, sortable: true, draggable: true},
    {name: 'createdAt', label: 'table.labels.created-at', visible: true, sortable: true, draggable: true, type: "date"},
    {name: 'resource', label: 'table.labels.resource', visible: true, sortable: true, draggable: true},
    {name: 'message', label: 'table.labels.message', visible: true, sortable: true, draggable: true},
  ];

  constructor(
    private errorService: ErrorService,
    private notificationsService: NotificationsService,
    private stateService: StateService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.stateService.currentNotifications$.subscribe(x => {this.notifications = x})
  }

  deleteElement(id: string) {
    this.notificationsService.delete(id).subscribe({
      error: (e) => this.errorService.handleError(e),
      complete: () => this.getData()
    })
  }

  markAsRead(id: string){
    this.notificationsService.readMessage(id).subscribe({
      error: (e) => this.errorService.handleError(e),
      complete: () => this.getData(),
    })
  }

  rowAction(event: any) {
    if(event.action ===  'delete') {
      this.deleteElement(event.row._id);
    }
    else if(event.action === 'markAsRead') {
      this.markAsRead(event.row._id);
    }
  }

}
