<div class="container-rec">
    <mat-card  appearance="outlined"  class="card">
        <mat-card-content>
            <div class="d-flex flex-column">
                <mat-form-field class="input-full-width">
                    <input matInput type="email" placeholder="Email" [(ngModel)]="email" email="true" required="true"#inputEmail="ngModel">
                    <mat-error *ngIf="inputEmail.errors?.email">
                        {{'recovery.email-error' | translate}}
                    </mat-error>
                </mat-form-field>
                <button [disabled]="inputEmail.errors?.email || inputEmail.errors?.required" class="input-full-width" mat-raised-button color="primary" (click)="onClick()">{{'button.recover' | translate}}</button>
                <button class="input-full-width mt-2" mat-stroked-button color="primary" routerLink="/login">{{'recovery.back' | translate}}</button>

            </div>
        </mat-card-content>
    </mat-card>
    <!-- <a class="linked" href="/login">{{'recovery.back' | translate}}</a> -->
</div>
