import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { AngularUiModule } from '@cosmos/angular/ui';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AngularUtilsModule, AuthInterceptor, AuthService, JwtService, StorageService } from '@cosmos/angular/utils';
import { LoginModule } from '@cosmos/angular/ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { initAuth } from './initAuth';
import { initConfig } from './initConfig';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { DashboardModule } from './dashboard/dashboard.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../../../../environments/environment';
import { AngularApiModule } from '@cosmos/angular/api';
import { NgChartsModule } from 'ng2-charts';
import { AngularTranslateModule } from '@cosmos/angular/translate';
import { provideClarity } from 'ngx-clarity';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production,
      //registrationStrategy: 'registerWhenStable:30000'
    }),
    AngularTranslateModule,
    // TranslateModule.forRoot({
    //   defaultLanguage: 'es',
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }),
    AppRoutingModule,
    // DashboardModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    AngularUiModule,
    LoginModule,
    AngularUtilsModule,
    AngularApiModule,
    NgChartsModule,
  ],
  providers: [

    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [AuthService, JwtService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [StorageService, TranslateService],
      multi: true
    },
    JwtService,
    //StateService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideClarity({
      enabled: true,
      projectId: 'i4pi7x7rdj',
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
