import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormFieldBase } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormFieldsClassDevices } from '../devices/devices.forms';
import { FormFieldsClassAssignCuringPool, FormFieldsClassMeasurement, FormFieldsClassReschedule } from '../measurement/measurement.forms';
import { FormFieldsClassProject } from '../projects/projects.forms';
import { FormFieldsClassProjectsShared } from '../projects/projectsShared.forms';
import { FormFieldsClassStage } from '../stage/stage.forms';
import { FormFieldsClassFileManager } from './fileManager.forms';
import { FormFieldsClassProjectConfigReports } from './projectConfigReports.forms';

@Component({
  selector: 'cosmos-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  public save = true;
  concretes: any;
  devices: any;
  title = '';
  fields: Observable<FormFieldBase<string>[]>;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    switch(this.data.type){
      case "changeDevice":{
        this.title = this.translateService.instant('dialog.title.device');
        const devices: {key: string, value: string}[] = [];
        for(const device of this.data.devices) devices.push({key: device['_id'], value: device['name'] });

        this.fields = new FormFieldsClassDevices(this.data, devices).getCreateFields();
        break;
      }
      case "project": {
        if(this.data.create) this.title = this.translateService.instant('dialog.title.create.project');
        else this.title = this.translateService.instant('dialog.title.edit.project') + ' ' + this.data.project.name;
        this.fields = new FormFieldsClassProject(this.data).getCreateFields();
        break;
      }
      case "stage": {
        if(this.data.create) this.title = this.translateService.instant('dialog.title.create.stage');
        else this.title = this.translateService.instant('dialog.title.edit.stage') + ' ' + this.data.stage.name;
        this.fields = new FormFieldsClassStage(this.data).getCreateFields();
        break;
      }
      case "measurement": {
        if(this.data.create)this.title = this.translateService.instant('dialog.title.create.measurement');
        else this.title = this.translateService.instant('dialog.title.edit.measurement') + ' ' + this.data.measurement.name;
        const concretes: {key: string, value: string, specifications: number[], specifications_maturity: number[]}[] = [];
        const devices: {key: string, value: string, lora: string}[] = [];

        if(this.data.concretes) {
          for(const concrete of this.data.concretes) concretes.push({key: concrete['_id'], value: concrete['name'], specifications: concrete['specifications'], specifications_maturity: concrete['specifications_maturity'] });
          this.data.concretes = concretes;
        }

        if(this.data.devices) {
          for(const device of this.data.devices) devices.push({key: device['_id'], value: device['name'], lora: device['loramac'] });
          this.data.devices = devices;
        }

        this.fields = new FormFieldsClassMeasurement(this.data, concretes, devices).getCreateFields();
        break;
      }
      case "shareProject": {
        this.title = this.translateService.instant('dialog.title.share-project');
        this.fields = new FormFieldsClassProjectsShared(this.data.project).getCreateFields();
        break;
      }
      case "fileManager": {
        this.save = false;
        this.title = this.translateService.instant('dialog.title.files');
        this.fields = new FormFieldsClassFileManager(this.data).getCreateFields();
        break;
      }
      case "projectConfigReports": {
        this.save = true;
        this.title = this.translateService.instant('dialog.title.reports-config');
        this.fields = new FormFieldsClassProjectConfigReports(this.data).getCreateFields();
        break;
      }
      // CHARI
      case "pool": {
        const curingPools: {key: string, value: string}[] = [];
        for(const curingPool of this.data.curingPools) curingPools.push({key: curingPool['_id'], value: curingPool['name'] });
        this.title = this.translateService.instant('dialog.title.select-curing-pool');
        this.fields = new FormFieldsClassAssignCuringPool({}, curingPools).getCreateFields();
      }
      // CAMBIO HORA INICIO
      case "reschedule": {
        const timestamps: {key: string, value: string}[] = [];
        for(const [index, timestamp] of this.data.measurement.timeAll.entries()) timestamps.push({key: index, value: new Date(timestamp).toLocaleString() });
        const startTimestamp = this.data.measurement.timeAll.findIndex( time => time == this.data.measurement.time[0]);
        const endTimestamp = this.data.measurement.timeAll.findIndex(time => time == this.data.measurement.time[this.data.measurement.time.length - 1]);
        console.log(startTimestamp, endTimestamp);
        this.title = this.translateService.instant('dialog.title.reschedule');
        this.fields = new FormFieldsClassReschedule({}, timestamps, startTimestamp, endTimestamp).getCreateFields();
      }
    }
  }

  handleEvent($event){
    this.dialogRef.close($event);
  }

}
