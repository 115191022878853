import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'cosmos-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  userNotifs = this.formBuilder.group({
    error:false,
    warning: false,
    information: false
  });

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
  }

}
