export class ColumnDefinition {
  name: string;
  label: string;
  visible: boolean;
  sortable: boolean;
  draggable: boolean;
  type?: string;
  footer?: any;
  tooltip?: boolean;
  rightBorder?: boolean;
};

export class ActionDefinition {
  name: string;
  label: string;
  icon: string;
  iconAction?: string;
  sticky: boolean;
  role?: string;
}
