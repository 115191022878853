import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GatewaysService } from '@cosmos/angular/api';
import { Gateway } from '@cosmos/shared';
import { ErrorService } from '@cosmos/angular/utils'
import { interval } from 'rxjs';

@Component({
  selector: 'cosmos-gateway-status',
  templateUrl: './gateway-status.component.html',
  styleUrls: ['./gateway-status.component.scss']
})
export class GatewayStatusComponent implements OnInit, OnDestroy {
  public timeComparison: number[] = [];
  timer;
  userGateways: any[];

  constructor(
    private errorService: ErrorService,
    private gatewayService: GatewaysService
  ) {
    this.gatewayService.findByUser().subscribe({
      next: (response) => this.userGateways = response.body,
      error: (e) => this.errorService.handleError(e),
      complete: () => {
        const now = new Date();
        this.userGateways.forEach(gateway => {
          this.timeComparison.push(now.valueOf() - new Date(gateway.chirpstackMetadata.lastSeenAt).valueOf());
        });
      }
    });
    this.timer = interval(300000).subscribe( x => {//every 5 mins
      this.gatewayService.findByUser().subscribe({
        next: (response) => this.userGateways = response.body,
        error: (e) => this.errorService.handleError(e),
        complete: () => {
          const now = new Date();
          this.userGateways.forEach((gateway, index) => {
            this.timeComparison[index] = (now.valueOf() - new Date(gateway.chirpstackMetadata.lastSeenAt).valueOf());
          });
        }
      });
    });
   }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.timer.unsubscribe();
  }
}
