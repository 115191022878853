<!-- <div class="row mb-4" *ngIf="curingPools.length > 0 && currentGroup === 'cards'"> -->
<div class="row mb-4" *ngIf="curingPools">
    <div class="col-md-6" *ngFor="let curingPool of curingPools">
        <!-- <pre>{{curingPool | json}}</pre> -->
        <mat-card  appearance="outlined"  class="my-2">
            <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar">{{curingPool.name[0]}}</div>
                <mat-card-title class="mb-1">{{curingPool.name}}</mat-card-title>
                <mat-card-subtitle>{{"cards.curingPool.subtitle" | translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-list>
                <ng-container *ngIf="curingPool.available">
                    <mat-list-item>
                        <mat-icon matListItemIcon>description</mat-icon>
                        <div matListItemTitle>{{"curingPool.unactive" | translate}}</div>
                        <div matListItemLine>{{ "curingPool.card.unlinked" | translate }}</div>
                      </mat-list-item>
                </ng-container>
                <ng-container *ngIf="!curingPool.available">
                    <mat-list-item>
                        <mat-icon matListItemIcon>description</mat-icon>
                        <div matListItemTitle>{{ "curingPool.card.linkedTo" | translate}} {{curingPool.measurement.name}}</div>
                        <div matListItemLine>{{ "curingPool.card.linkedMeasurement" | translate }}</div>
                    </mat-list-item>
                </ng-container>
              </mat-list>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" routerLink="{{curingPool._id}}">{{"button.details" | translate}}</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>