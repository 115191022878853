<button  mat-icon-button [matMenuTriggerFor]="menu" >
  <div matBadge="{{notifications.length}}" matBadgePosition="after" matBadgeSize="medium" matBadgeColor="warn"></div>
  <mat-icon>notifications</mat-icon>
</button>
<mat-menu #menu="matMenu">
  @for (notification of (notifications ? notifications.slice(0,5): []); track notification) {
    <div mat-menu-item class="outline">
      <div
      [ngStyle]="{'color':
        (notification.type === 'error') ? 'Orange' :
        (notification.type === 'warning') ? 'Gold' :
        (notification.type === 'info') ? 'LimeGreen' :''
      }">
        {{notification.resourceId.name}} - {{notification.message | translate}}
      </div>
    </div>
    <mat-divider></mat-divider>
  }
  <!-- <div mat-menu-item class="outline" *ngFor="let notification of (notifications ? notifications.slice(0,5): [])">
    <div
    [ngStyle]="{'color':
      (notification.type === 'error') ? 'OrangeRed' :
      (notification.type === 'warning') ? 'Gold' :
      (notification.type === 'info') ? 'LimeGreen' :''
    }">
    {{notification.resource}}
      {{notification.resourceId.name}} - {{notification.message | translate}}
    </div>
    <mat-divider></mat-divider> -->
  <!-- </div> -->
  


  <!-- <button class="center" mat-menu-item routerLink="/notifications">
    <span [ngStyle]="{'font-weight': 'bold'}">
      {{"notifications.see-all" | translate}}
      <mat-icon>read_more</mat-icon>
    </span>
  </button> -->
  <!-- <div mat-menu-item class="outline">Ver más...</div> -->

</mat-menu>
