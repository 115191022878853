<div class="container-login mat-drawer-container">
    <mat-card  appearance="outlined"  class="login-card">
    <mat-card-header>
        <div mat-card-avatar class="text-center card-avatar"><mat-icon>person_add</mat-icon></div>
        <mat-card-title class="mb-1">{{'signup.create-account' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'signup.card-subtitle' | translate}}</mat-card-subtitle>
    </mat-card-header>
      <!-- <img src="" class="login-img"> -->
      <mat-card-content class="mt-3">
        <form class="login-form" [formGroup]="signupForm" (ngSubmit)="onSubmit()" class="d-flex flex-column">
          <mat-form-field class="input-full-width" required>
              <input matInput type="text" [placeholder]="'fields.name' | translate" formControlName="name">
          </mat-form-field>

          <mat-form-field class="input-full-width" required>
              <input matInput type="text" [placeholder]="'fields.surname' | translate" formControlName="surname">
          </mat-form-field>
          
          <mat-form-field class="input-full-width" required>
              <input matInput type="email" [placeholder]="'fields.email' | translate" formControlName="email">
              <mat-hint>{{ 'signup.email-hint' |translate }}</mat-hint>
          </mat-form-field>
    
          <mat-form-field class="input-full-width" required class="mt-4">
            <input matInput type="password" [placeholder]="'fields.password' | translate" formControlName="password" >
          </mat-form-field>

          <mat-form-field class="input-full-width" required>
              <input matInput type="text" [placeholder]="'fields.company-text' | translate" formControlName="company_text">
          </mat-form-field>
          <!-- ADD PHONE -->
          <mat-form-field class="input-full-width">
            <input matInput type="text" [placeholder]="'fields.phone' | translate" formControlName="phone">
          </mat-form-field>

          <mat-checkbox class="mb-3" formControlName="terms" required>{{'fields.terms' | translate}} - <a class="" href="https://digitalizatuhormigon.com/assets/terms.txt" target="_blank">{{"fields.open-terms" | translate}}</a></mat-checkbox>

          <button *ngIf="!loading" [disabled]="!signupForm.valid" class="input-full-width" type="submit" mat-raised-button color="primary">{{'button.signup' | translate}}</button>
        </form>
        <div class="d-flex flex-column">
          <button class="w-100 mt-2" mat-stroked-button color="primary" routerLink="/login">{{'signup.back-to-login' | translate}}</button>
        </div>
      </mat-card-content>
  
    </mat-card>
  </div>
  