import { LoraMetadata } from "./lora-metadata.model";

export class Gateway {
  _id?: string;
  name?: string;
  mac?: string;
  available?: boolean;
  project?: any;
  company?: any;
  updatedAt?: Date;
  createdAt?: Date;
  chirpstackMetadata?: any;
  transmission?: LoraMetadata[];
  version?: string;
}
