import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Device, Gateway } from '@cosmos/shared';
import { ErrorService, StorageService } from '@cosmos/angular/utils';
import { ColumnDefinition } from '@cosmos/angular/ui';

@Component({
  selector: 'cosmos-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {

  currentGroup: string;

  columnsDevice: ColumnDefinition[] = [
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'available', label: 'table.labels.available', visible: true, sortable: true, draggable: true},
    {name: 'lastseen', label: 'table.labels.updated', visible: true, sortable: true, draggable: true, type: "date"},
    {name: 'battery', label: 'table.labels.battery', visible: true, sortable: true, draggable: true},
    {name: 'project.name', label: 'table.labels.project', visible: true, sortable: true, draggable: true},
    {name: 'transmission', label: 'table.labels.transmission', visible: true, sortable: true, draggable: true, type: 'transmission'},
  ];

  columnsGateway: ColumnDefinition[] = [
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'available', label: 'table.labels.available', visible: true, sortable: true, draggable: true},
    {name: 'company.name', label: 'table.labels.company', visible: true, sortable: true, draggable: true},
    {name: 'project.name', label: 'table.labels.project', visible: true, sortable: true, draggable: true},
  ];


  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private storageService: StorageService,
  ) { }

  devices: Device[] = [];
  gateways: Gateway[] = [];

  ngOnInit(): void {
    this.currentGroup = this.storageService.getItem("groupDisplay");

    this.http.get<Device[]>(`${environment.API_URL}/devices?populate_measurement=name,stage&populate_project=name`, {observe: 'response'})
    .subscribe({
      next: (event: any) => this.devices = event.body
    });

    this.http.get<Gateway[]>(`${environment.API_URL}/gateways/usergateways/?populate_company=name&populate_project=name`, {observe: 'response'})
    .subscribe({
      next: (event: any) => this.gateways = event.body
    });
  }

  updateStorage(selectedGroup: string){
    this.storageService.setItem('groupDisplay',selectedGroup);
    this.currentGroup = selectedGroup;
  }

}
