import { Component, OnInit } from '@angular/core';
import { CuringPoolssService } from '@cosmos/angular/api';
import { ErrorService } from '@cosmos/angular/utils';
import { CuringPool } from '@cosmos/shared';

@Component({
  selector: 'cosmos-curing-pool',
  templateUrl: './curing-pool.component.html',
  styleUrls: ['./curing-pool.component.scss']
})
export class CuringPoolComponent implements OnInit {

  curingPools: CuringPool[]
  constructor(
    private curingPoolService: CuringPoolssService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.curingPoolService.find('?select=name,measurement,available&populate_measurement=name').subscribe({
      next: (response) => {console.log(response.body); this.curingPools = response.body},
      error: (e) => this.errorService.handleError(e),
      complete: () => {}
    })
  }

}
