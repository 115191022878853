import { FormFieldBase, FormFieldCheckBox, FormFieldFileSelector, FormFieldFileManager, FormFieldTextbox } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClassProjectConfigReports {
  formFields: FormFieldBase<any>[];

  constructor(data:any) {
    console.log(data);
    // COMMON FIELDS
    this.formFields = [
        new FormFieldFileManager({
          key:'file',
          label: "fields.files",
          value: data.fileManager,
          required: false,
          type: "Projects/" + data.fileManager.project._id + "/ReportLogo",
          order: 4,
          context: 'reportLogo'
        }),
        new FormFieldCheckBox({
          key:'reportsEnabled',
          label: "fields.enable-reports",
          value: data.reportsEnabled === "true" || data.reportsEnabled === true,
          required: false,
          order: 1,
        }),
        new FormFieldTextbox({
          key: 'reportsEmail',
          label: 'fields.report-email',
          value: data.reportsEmail,
          required: false,
          order: 2
        }),
        new FormFieldFileSelector({
          key: 'reportImage',
          label: 'fields.report-image',
          value: data.reportsImage.value,
          options: data.reportsImage.options,
          required: false,
          order: 3
        })

    ]
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
