import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GatewaysService } from '@cosmos/angular/api';
import { ErrorService } from '@cosmos/angular/utils';
import { Gateway } from '@cosmos/shared';

@Component({
  selector: 'cosmos-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss']
})
export class GatewayComponent implements OnInit {

  gateway: Gateway;

  constructor(
    private gatewayService: GatewaysService,
    private router: Router,
    private errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    const url = (this.router.url).split("/");

    this.gatewayService.findById(url[url.length-1], '?populate_company=name&populate_project=name').subscribe({
      next: (response) => {console.log(response); this.gateway = response.body},
      error: (e) => this.errorService.handleError(e),
      complete: () => {}
    });
  }
}
