export class Simulation {
    _id?: string;
    startDate?: Date;
    concreteCycles?: number;
    concreteMixture?: any;
    region?: string;
    strengthReq?: number;
    owner: any;
    completed: boolean;
    details: boolean;
    public: boolean;
    quoteRequested: boolean;
    quotePhone: string;
    cubicMeters: number;
    impact: number;
    results: {
      maturity: {
        stages: {
          stage: Number;
          start: {date: Date, index: number};
          end: {date: Date, index: number};
          strength?: Number;
          regulation_requirements?: Number;
        }[],
        arrays: {
          days_array: Number[];
          maturity_array?: Number[];
          temp_array: Number[];
          strength_array?: Number[];
          cycles_array: Number[];
          regulation_array: Number[];
        }
      },
      fixed10: {
        stages: {
          stage: Number;
          start: {date: Date, index: number};
          end: {date: Date, index: number};
          strength?: Number;
          regulation_requirements?: Number;
        }[],
        arrays: {
          days_array: Number[];
          maturity_array?: Number[];
          temp_array: Number[];
          strength_array?: Number[];
          cycles_array: Number[];
          regulation_array: Number[];
        }
      },
      regulation: {
        stages: {
          stage: Number;
          start: {date: Date, index: number};
          end: {date: Date, index: number};
          strength?: Number;
          regulation_requirements?: Number;
        }[],
        arrays: {
          days_array: Number[];
          maturity_array?: Number[];
          temp_array: Number[];
          strength_array?: Number[];
          cycles_array: Number[];
          regulation_array: Number[];
        }
      }
    }
    // concreteStages: {
    //     stage: Number;
    //     start: {date: Date, index: number};
    //     end: {date: Date, index: number};
    //     strength: Number;
    //   }[];
    // arrays: {
    //     days_array: Number[];
    //     maturity_array: Number[];
    //     temp_array: Number[];
    //     strength_array: Number[];
    //     cycles_array: Number[];
    //   }
}
