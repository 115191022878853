import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard.component';
import { LandingComponent } from './landing/landing.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { NavigationComponent } from './navigation/navigation.component';
import { AngularUiModule, UserDropdownComponent, LanguageDropdownComponent } from '@cosmos/angular/ui';


import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SidenavComponent } from './navigation/sidenav/sidenav.component';
import { GatewayStatusComponent } from './landing/gateway-status/gateway-status.component';
import { WarningsComponent } from './landing/warnings/warnings.component';
import { ActiveMeasurementsComponent } from './landing/active-measurements/active-measurements.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { UserComponent } from './pages/user/user.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SharedComponent } from './pages/shared/shared.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SupportComponent } from './pages/support/support.component';
import { ProjectComponent } from './pages/project/project.component';
import { StageComponent } from './pages/stage/stage.component';
import { MeasurementComponent } from './pages/measurement/measurement.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { DeviceComponent } from './pages/device/device.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { GatewayComponent } from './pages/gateway/gateway.component';
import { DialogComponent } from './pages/dialog/dialog.component';
import { NgChartsModule } from 'ng2-charts';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DynamicScriptDirective } from '@cosmos/angular/utils';
import { CuringPoolComponent } from './pages/curing-pool/curing-pool.component';
import { CuringPoolDetailComponent } from './pages/curing-pool/curing-pool-detail/curing-pool-detail.component';
import { ProjectVisualizationDialogComponent } from './pages/dialog/project-visualization-dialog/project-visualization-dialog.component';
import { ProjectNotificationsDialogComponent } from './pages/dialog/project-notifications-dialog/project-notifications-dialog.component';


@NgModule({
  declarations: [
    NavigationComponent,
    DashboardComponent,
    LandingComponent,
    SidenavComponent,
    GatewayStatusComponent,
    WarningsComponent,
    ActiveMeasurementsComponent,
    NotificationsComponent,
    UserComponent,
    SettingsComponent,
    SharedComponent,
    ReportsComponent,
    SupportComponent,
    ProjectComponent,
    StageComponent,
    MeasurementComponent,
    DevicesComponent,
    DeviceComponent,
    ProjectsComponent,
    GatewayComponent,
    DialogComponent,
    ProjectVisualizationDialogComponent,
    ProjectNotificationsDialogComponent,
    CuringPoolComponent,
    CuringPoolDetailComponent,
    DynamicScriptDirective,
  ],
  imports: [
    PdfViewerModule,
    CommonModule,
    DashboardRoutingModule,
    AngularUiModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    // MatButtonToggleModule,
    MatProgressBarModule,
  ],
  exports: [
  ],
  providers: [
    TranslateService,
  ]
})

export class DashboardModule {}
