import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from '@cosmos/angular/utils';
import { Project } from '@cosmos/shared';
import { ColumnDefinition } from '../../data-table/column-definition.model';

@Component({
  selector: 'cosmos-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {

  @Input() projects: Project[];
  currentGroup: string;

  columns: ColumnDefinition[] = [
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'description', label: 'table.labels.description', visible: true, sortable: true, draggable: true},
  ];

  constructor(private storageService: StorageService) { }

  ngOnInit(): void {
    this.currentGroup = this.storageService.getItem("groupDisplay");
  }

  updateStorage(selectedGroup: string){
    this.storageService.setItem('groupDisplay',selectedGroup);
    this.currentGroup = selectedGroup;
  }

}
