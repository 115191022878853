<div class="container-reset">
    <mat-card  appearance="outlined"  class="card">
        <mat-card-content>
            <div class="d-flex flex-column">
                <mat-form-field class="input-full-width">
                    <input [disabled]="completed" matInput [type]="hide1 ? 'password' : 'text'" placeholder="New Password" required="true" [(ngModel)]="password" #inputPassword="ngModel">
                    <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                        <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="input-full-width">
                    <input [disabled]="completed" matInput [type]="hide2 ? 'password' : 'text'" placeholder="Confirm Password" required="true" #confirmPassword>
                    <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                        <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-hint *ngIf="inputPassword.value !== confirmPassword.value">
                        {{'recovery.password-not-match' | translate}}
                    </mat-hint>
                </mat-form-field>
                <button [disabled]="inputPassword.value !== confirmPassword.value || inputPassword.errors?.required || confirmPassword.errors?.required || completed" class="input-full-width" type="submit" mat-raised-button color="primary" (click)="onClick()">{{'button.save' | translate}}</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>

