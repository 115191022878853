import { Component, Input, OnInit } from '@angular/core';
import { ConversionService } from '@cosmos/angular/utils';
import { Gateway, LoraMetadata } from '@cosmos/shared';


@Component({
  selector: 'cosmos-gw-card',
  templateUrl: './gw-card.component.html',
  styleUrls: ['./gw-card.component.scss']
})
export class GwCardComponent implements OnInit {

  @Input() gateway: Gateway;
  @Input() showNavigation: boolean = false;
  @Input() details: boolean = false;

  constructor(
    private conversionService: ConversionService,
  ) { }

  ngOnInit(): void {
  }
  
  getTransmissionDetails(transmission: LoraMetadata) {
    return this.conversionService.getTransmissionInfo(transmission);
  }
}
