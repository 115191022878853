export class FileE {
    _id?: string;
    filename?: string;
    path?: string;
    filetype?: string;
    owner?: string;
    service?: string;
    serviceId?: string;
    company?: string;
}
