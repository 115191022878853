<h1 mat-dialog-title>{{title}}</h1>

<mat-dialog-content>
  <p>{{"projects.notificationsConfig.intro" | translate }}</p>

  <section class="" [formGroup]="notificationsConfig">
    <mat-form-field class="w-100">
      <mat-label>Emails</mat-label>
      <mat-chip-grid #chipGrid formControlName="emails">
          <mat-chip-row *ngFor="let chip of chipValues" [value]="chip" (removed)="chipInputRemove(chip)">
            {{chip}}
            <button matChipRemove><mat-icon>cancel</mat-icon></button>
          </mat-chip-row>
      </mat-chip-grid>
      <input type="email" email
        [formControl]="inputControl"
        placeholder="Nuevo email..."
        [matChipInputFor]="chipGrid"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-form-field>
    <ul>    
      <li formGroupName="strengthRequirements">Notificar requisitos de resistencia máximos:
        <mat-checkbox formControlName="app">App</mat-checkbox>
        <mat-checkbox formControlName="email">Email</mat-checkbox>
      </li>
    </ul>
  </section>

  <div class="form-row justify-content-center">
      <button mat-raised-button color="primary" (click)="saveForm()">{{ ('button.save') | translate}}</button>
      <!-- <button mat-icon-button color="primary" (click)="saveForm()" class="right_header"><mat-icon>close</mat-icon></button> -->
  </div>

</mat-dialog-content>
