<section class="checkboxes" [formGroup]="userNotifs">
    <p><mat-checkbox class="margin" formControlName="error">{{'notifications.type.error' | translate}}</mat-checkbox></p>
    <p><mat-checkbox class="margin" formControlName="warning">{{'notifications.type.warning' | translate}}</mat-checkbox></p>
    <p><mat-checkbox class="margin" formControlName="information">{{'notifications.type.information' | translate}}</mat-checkbox></p>
</section>


<button mat-raised-button color="primary"
    [disabled]="userNotifs.value.error === user.notification.error &&
                userNotifs.value.warning === user.notification.warning &&
                userNotifs.value.information === user.notification.information"
    (click)="saveNotifs()">
        {{"notifications.save-settings" | translate}}
</button>
