<!-- TOGGLE -->
<mat-button-toggle-group *ngIf="devices.length > 0" class="" #group="matButtonToggleGroup" (change)="updateStorage(group.value)" value="{{currentGroup}}">
  <mat-button-toggle value="table" aria-label="Table">
      <mat-icon>list</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="cards" aria-label="Cards">
      <mat-icon>apps</mat-icon>
      </mat-button-toggle>
</mat-button-toggle-group>

<!-- ----------------------------------------------------------------- -->
<!-- DEVICES -->
<!-- ----------------------------------------------------------------- -->

<p class="h4 mb-0">{{"devices.devices" | translate}}</p>

<mat-divider></mat-divider>
<!-- CARDS -->
<div class="row mb-4" *ngIf="devices.length > 0 && currentGroup === 'cards'">
  <div class="col-md-4" *ngFor="let device of devices">
    <cosmos-device-card [device]="device" [showNavigation]="true"></cosmos-device-card>
  </div>
</div>
<!-- TABLE -->
<cosmos-data-table
    *ngIf="devices.length > 0 && currentGroup === 'table'"
    [isLinked]="'device'"
    [tableData]="devices"
    [tableColumns]="columnsDevice"
    [isFilterable]="true"
    [toggleColumnsVisibility]="true"
    (rowAction)="rowAction($event)">
</cosmos-data-table>
<!-- NONE -->
<div class="row mb-4" *ngIf="devices.length === 0">
  <div class="col-md">
    {{"devices.not-found" | translate}}
  </div>
</div>


<!-- <mat-divider></mat-divider> -->

<!-- ----------------------------------------------------------------- -->
<!-- GATEWAYS -->
<!-- ----------------------------------------------------------------- -->

<p class="h4 mb-0">{{"devices.gateways" | translate}}</p>

<mat-divider></mat-divider>
<!-- CARDS -->
<div class="row mb-4" *ngIf="gateways.length > 0 && currentGroup === 'cards'">
  <div class="col-md-4" *ngFor="let gateway of gateways">
    <cosmos-gw-card [gateway]="gateway" [showNavigation]="true"></cosmos-gw-card>
  </div>
</div>
<!-- TABLE -->
<cosmos-data-table
    *ngIf="gateways.length > 0 && currentGroup === 'table'"
    [tableData]="gateways"
    [isLinked]="'gateway'"
    [tableColumns]="columnsGateway"
    [isFilterable]="true"
    [toggleColumnsVisibility]="true"
    (rowAction)="rowAction($event)">
</cosmos-data-table>


<!-- NONE -->
<div class="row mb-4" *ngIf="gateways.length === 0">
  <div class="col-md">
    {{"gateways.not-found" | translate}}
  </div>
</div>

