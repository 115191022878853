export class User{
  _id?: string;
  name?: string;
  surname?: string;
  email?: string;
  password?: string;
  roles?: string[];
  company?: string;
  phone?: string;
  validated?: boolean;
  authorized?: boolean;
  notification?: any;
  createdAt?: Date;
  updatedAt?: Date;
}
