<cosmos-data-table
  *ngIf="notifications.length > 0"
  [defaultSort]="'read'"
  [tableData]="notifications"
  [tableColumns]="columns"
  [isFilterable]="true"
  [toggleColumnsVisibility]="true"
  [rowActions]="actions"
  (rowAction)="rowAction($event)">
</cosmos-data-table>

<div class="row mb-4" *ngIf="notifications.length === 0">
  <div class="col-md">
      {{"notifications.empty" | translate}}
  </div>
</div>
