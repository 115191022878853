<mat-sidenav-container autosize> <!-- style="z-index: 1; flex: 1 1 auto;"  class="sidenav-container"-->
  <mat-sidenav #sidenav fixedInViewport="false" mode="side" [opened]="openNav">

    <div style="height: 100%; display:  flex; flex-direction: column;">
      <!-- DATOS -->
      <mat-nav-list>
        <mat-list-item routerLink="/projects">
          <mat-icon matListItemIcon>folder</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{ "dashboard.sidenav.my-projects" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/shared">
          <mat-icon matListItemIcon>folder_shared</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{ "dashboard.sidenav.shared" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list>

      <!-- HARDWARE -->
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item routerLink="/devices">
          <mat-icon matListItemIcon>devices</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{ "dashboard.sidenav.hardware" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list>

      <mat-divider></mat-divider>

      <mat-nav-list *ngIf="user.roles.includes('admin')">
        <mat-list-item routerLink="/lab/curing-pools">
          <mat-icon matListItemIcon>waves</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{ "dashboard.sidenav.curing-pool" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list>

      <!-- <mat-divider></mat-divider> -->

      <!-- REPORTS -->
      <!-- <mat-nav-list>
        <mat-list-item routerLink="/reports">
          <mat-icon matListItemIcon>description</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{ "dashboard.sidenav.reports" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list> -->

      <mat-divider style="flex-grow:1"></mat-divider>

      <!-- CONFIGURACION Y SOPORTE -->
      <mat-nav-list>
        <mat-list-item routerLink="/support">
          <mat-icon matListItemIcon>help</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{ "dashboard.sidenav.support" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/settings">
          <mat-icon matListItemIcon>settings</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{ "dashboard.sidenav.settings" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list>

      <!-- EXPANDIR Y COLAPSAR -->
      <mat-divider></mat-divider>

      <mat-nav-list>
        <mat-list-item *ngIf="!isExpanded" (click)="expandSidebar(true)">
          <mat-icon matListItemIcon>navigate_next</mat-icon>
        </mat-list-item>
        <mat-list-item *ngIf="isExpanded" (click)="expandSidebar(false)">
          <mat-icon matListItemIcon >navigate_before</mat-icon>
        </mat-list-item>
      </mat-nav-list>
      <!-- SIDENAV
      asdf
    <ul><li>1</li><li>2</li></ul> -->

    </div>
  </mat-sidenav>

  <mat-sidenav-content > <!-- style="height: 100%; overflow: hidden;" -->
    <!-- <div style="margin-top: 64px; overflow: hidden;"></div> -->
    <main class="container-fluid">
    <!-- <div class="container-fluid" style="overflow-y: auto; overflow-x: hidden;" > -->
      <cosmos-breadcrumbs></cosmos-breadcrumbs>
      <router-outlet></router-outlet>
    <!-- </div> -->
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
