<mat-card  appearance="outlined"  class="my-2">
    <mat-card-header>
        <div mat-card-avatar class="text-center card-avatar">{{device.name[0]}}</div>
        <mat-card-title class="mb-1">{{device.name}}</mat-card-title>
        <mat-card-subtitle>{{"cards.device.subtitle" | translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <!-- MAKE MAT-LIST FOR PROPERTIES -->
        <mat-list>
          <div mat-subheader>{{"cards.device.properties-section" | translate}}</div>
          <mat-list-item>
            <mat-icon matListItemIcon>qr_code_2</mat-icon>
            <div matListItemTitle>{{device.loramac}}</div>
            <div matListItemLine> ID </div>
          </mat-list-item>

          <mat-list-item>
            <mat-icon matListItemIcon>folder_open</mat-icon>
            <div matListItemTitle>{{device.project?device.project?.name:'-'}}</div>
            <div matListItemLine>{{"cards.device.project" | translate}}</div>
          </mat-list-item>
          <!-- USED / FREE -->
          <mat-list-item *ngIf="!device.available">
            <mat-icon matListItemIcon>event_busy</mat-icon>
            <div matListItemTitle>{{"cards.device.measurement" | translate}} {{device.measurement?device.measurement?.name:'-'}}</div>
            <div matListItemLine>{{"cards.device.used" | translate}}</div>
          </mat-list-item>
          <mat-list-item *ngIf="device.available">
            <mat-icon matListItemIcon>event_available</mat-icon>
            <div matListItemTitle>{{"cards.device.available" | translate}}</div>
            <div matListItemLine>{{"cards.device.available-subtitle" | translate}}</div>
          </mat-list-item>

          <mat-divider></mat-divider>
          <div mat-subheader>{{"cards.device.data-section" | translate}} ({{device.lastseen?(device.lastseen | date:'dd/MM/yyyy HH:mm'):'-'}})</div>
          <!-- <mat-list-item>
            <mat-icon matListItemIcon>update</mat-icon>
            <div matListItemTitle>{{device.lastseen | date:'dd/MM/yyyy HH:mm:ss'}}</div>
            <div matListItemLine>{{"cards.device.updated" | translate}}</div>
          </mat-list-item> -->
          <mat-list-item>
            <mat-icon matListItemIcon>{{device.battery?getBattery(device.battery)[1]:'battery_unknown'}}</mat-icon>
            <div matListItemTitle>{{ device.battery?getBattery(device.battery)[2]:"-" }}</div>
            <div matListItemLine>{{"cards.device.battery" | translate}}</div>
          </mat-list-item>
          <mat-list-item
              [matTooltip]="
                device.transmission.length > 0 ?
                  'RSSI: ' + device.transmission[device.transmission.length -1].rssi + ' - SNR: ' + device.transmission[device.transmission.length -1].snr :
                  ('cards.device.no_signal_data' | translate)
                "
              matTooltipPosition="below">
            <mat-icon matListItemIcon>{{device.transmission.length > 0?getTransmissionDetails(device.transmission[device.transmission.length -1])[1]:'signal_cellular_nodata'}}</mat-icon>
            <div matListItemTitle>{{ device.transmission.length > 0?getTransmissionDetails(device.transmission[device.transmission.length -1])[2]:"-" }}</div>
            <div matListItemLine>{{"cards.device.signal" | translate}}</div>
          </mat-list-item>
        </mat-list>
        <!-- <p>ID: {{device.loramac}}</p> -->
        <!-- <p *ngIf="!device.available">{{"cards.device.used" | translate}}</p> -->
        <!-- <p> {{"cards.device.updated" | translate}} {{device.lastseen | date:'dd/MM/yyyy HH:mm:ss'}}</p> -->
        <!-- <p> {{"cards.device.battery" | translate}} {{device.battery}} </p> -->
        <!-- <p *ngIf="device.transmission.length > 1;"> {{"cards.device.transmission" | translate}}: {{device.transmission[device.transmission.length -1] |json }}</p> -->
        <!-- <mat-expansion-panel *ngIf="device.transmission.length > 1;">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon
                [attr.style]="'color:' + getTransmissionDetails(device.transmission[device.transmission.length -1])[0]">
                  {{ getTransmissionDetails(device.transmission[device.transmission.length -1])[1] }}
                </mat-icon>
                {{getTransmissionDetails(device.transmission[device.transmission.length -1])[2]}}
            </mat-panel-title>
            <mat-panel-description>
              Señal
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngFor="let transmission of device.transmission.slice().reverse()">
            <p
              [matTooltip]="'RSSI: ' + transmission.rssi + ' - SNR: ' + transmission.snr"
              matTooltipPosition="right">
              {{simple}}
                {{transmission.timestamp | date:'dd/MM/yyyy HH:mm:ss'}} - {{getTransmissionDetails(transmission)[2]}}. Data: {{transmission.data.temperature}} ºC
            </p>
            <mat-divider></mat-divider>
          </div>
        </mat-expansion-panel> -->
        <!--  -->
    </mat-card-content>
    <mat-card-actions *ngIf="showNavigation">
      <button mat-raised-button color="primary" routerLink="d/{{device._id}}">{{"button.details" | translate}}</button>
      <button *ngIf="!device.available && device.project && device.stage" mat-stroked-button color="primary" routerLink="/projects/{{device.project._id}}/{{device.measurement.stage}}/{{device.measurement._id}}">{{"cards.device.view_measurement" | translate}}</button>
    </mat-card-actions>
</mat-card>
