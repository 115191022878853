export class Project {
  _id?: string;
  name?: string;
  description?: string;
  owner?: string;
  company?: string;
  location?: any;
  shared_w?: string[];
  shared_r?: string[];
  data?: any;
  plano?: string;
  reportImage?: string;
  reportsEnabled?: boolean;
  reportsEmail?: string;
  files?: any[];
  updatedAt?: Date;
  createdAt?: Date;
  premium?: boolean;
  bim?: boolean;
  bimUrn?: string;
  number_measurements?: number;
  visualizationConfig?: ProjectVisualizationConfig;
  notificationsConfig?: ProjectNotificationsConfig;
}

export class ProjectVisualizationConfig {
  maturity: VisualizationConfig;
  relativeMaturity: VisualizationConfig;
  maturityThreshold: VisualizationConfig;
}

class VisualizationConfig {
  app: boolean;
  report: boolean;
}

export class ProjectNotificationsConfig {
  emails: string[];
  strengthRequirements: NotificationsConfig;
}

class NotificationsConfig {
  app: boolean;
  email: boolean;
}
