export class Concrete {
    _id?: string;
    name?: string;
    A_VAL?: number;
    B_VAL?: number;
    ABS_TEMP?: number;
    owner?: string;
    type?: string;
    log?: string;
    company?: string[];
    project?: string[];
    updatedAt?: Date;
    createdAt?: Date;
    specifications?: number[];
    specifications_maturity?: number[];
  }
