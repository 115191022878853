<div class="container-login mat-drawer-container">
    <mat-card  appearance="outlined"  class="card">
        <mat-card-content>
            <div class="d-flex flex-column">
                <p class="my-3">{{ validateText }}</p>
                <button [disabled]="validating" class="input-full-width" mat-raised-button color="primary" routerLink="/login">{{'signup.back-to-login' | translate}}</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>

