import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { Notification } from '@cosmos/shared';
import { environment } from '../../../../../../environments/environment';
import { catchError, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private stateService: StateService,
  ) { }

  setNotifications() {
    this.http
      .get<Notification[]>(`${environment.API_URL}/notifications/usernotif?populate_resourceId=name`, {observe: 'response'}).subscribe({
        // next works, dont know why VS shows error
        next: (response) => {this.stateService.setCurrentNotifications(response.body as Notification[]); console.log(response.body)},
        error: (error) => this.errorService.handleError(error)
      })
  }


  // findById(id: string): Observable<HttpResponse<Notification>> {
  //   return this.http
  //     .get<Notification>(`${environment.API_URL}/notifications/${id}`, {observe: 'response'})
  //     .pipe(
  //       map(response => {return response;}),
  //       catchError( (error) => this.errorService.handleError(error))
  //     );
  // }

  // NOTIFICATIONS ARE CREATED FROM EVENTS; SO FRONT END APPS SHOULD NOT CREATE THEM
  //
  // create(body: Notification) {
  //   return this.http
  //     .post(`${environment.API_URL}/notifications`, body, {observe: 'response'})
  //     .pipe(
  //       map(response => {return response;}),
  //       catchError( (error) => this.errorService.handleError(error))
  //     );
  // }

  
  // update(body: any) {
  //   return this.http
  //     .patch(`${environment.API_URL}/notifications/${body[0]}`, body[1], {observe: 'response'})
  //     .pipe(
  //       map(response => {return response;}),
  //       catchError( (error) => this.errorService.handleError(error))
  //     );
  // }

  readMessage(id: string){
    return this.http
      .patch(`${environment.API_URL}/notifications/${id}`,{read: true}, {observe: 'response'})
      .pipe(
        map(response => {this.setNotifications();return response;}),
        catchError( (error) => this.errorService.handleError(error)),
      )
  }

  delete(id: string) {
    return this.http
      .delete(`${environment.API_URL}/notifications/${id}`, {observe: 'response'})
      .pipe(
        map(response => {this.setNotifications();return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }
}
