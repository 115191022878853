import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@cosmos/angular/utils';
import { Notification } from '@cosmos/shared';
import { map, Subscription } from 'rxjs';

@Component({
  selector: 'cosmos-notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrls: ['./notification-dropdown.component.scss']
})
export class NotificationDropdownComponent implements OnInit, OnDestroy {

  userSettings: any;
  notifications: Notification[] = [];
  notifSubscription: Subscription;
  userSubscription: Subscription;

  constructor(
    private stateService : StateService,
    ) { }

  ngOnInit(): void {
    this.userSubscription = this.stateService.currentUser$.subscribe(x => {if(x) {this.userSettings = x?.notification; this.getData();}});
  }

  ngOnDestroy(): void {
      this.notifSubscription.unsubscribe();
      this.userSubscription.unsubscribe();
  }

  getData(){
    this.notifSubscription = this.stateService.currentNotifications$
      .pipe(
        map(
          //Filters the notifs so that only the unread ones show, and then sorts them by most recently created
          notifs => (
            notifs.filter(noti => !noti.read))
            // .sort((a, b) => <any>new Date(b.createdAt) - <any>new Date(a.createdAt))
          ))
        .subscribe(x => {this.notifications = x;})
  }

}
