<div class="row mt-6">
  <div class="col-md mb-4">
    <h2>{{"settings.title" | translate}}</h2>
    <h5>{{"settings.description" | translate}}</h5>
  </div>

  <mat-divider></mat-divider>

  <div class="col-md my-4">
    <h4>{{"settings.language" | translate}}</h4>
    <p>{{"settings.lang_description" | translate}}</p>

    <cosmos-language-dropdown></cosmos-language-dropdown>
  </div>

  <mat-divider></mat-divider>

  <div class="col-md my-4">
    <h4>{{"settings.theme" | translate}}</h4>
    <p>{{"settings.theme_description" | translate}}</p>

    <cosmos-styles-dropdown></cosmos-styles-dropdown>
  </div>

  <mat-divider></mat-divider>

  <div class="col-md my-4">
    <h4>{{"settings.notifications" | translate}}</h4>
    <p>{{"settings.notif_description" | translate}}</p>

    <cosmos-notification-settings></cosmos-notification-settings>
  </div>
</div>
