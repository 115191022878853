import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { catchError, EMPTY, map } from 'rxjs';

@Component({
  selector: 'cosmos-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  token: string;
  hide1 = true;
  hide2 = true;
  password: string;
  completed: boolean = false;

  constructor(
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
    })
  }

  ngOnInit(): void {
  }

  onClick(): void {
    this.completed = true;
    /*
    let snackBar = this.snackBar.open("Password Changed!","OK", {
      duration: 5000
    });
    snackBar.afterDismissed().subscribe(() => {
      this.router.navigate(['/']);
    });*/


    const body = {token: this.token, password: this.password};

    this.http.post(`${environment.API_URL}/resetpassword`, body, {observe: 'response'}).subscribe({
      next: () => {
        let snackBar = this.snackBar.open(this.translateService.instant('recovery.password-changed'),"OK", {duration: 5000 });
        snackBar.afterDismissed().subscribe(() => {
          this.router.navigate(['/']);
        })
      },
      error: (e) => { this.router.navigate(['/']);}
    })

  }
}
