import { Component, OnInit } from '@angular/core';
import { CuringPoolssService } from '@cosmos/angular/api';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { CuringPool, User } from '@cosmos/shared';
import { Router } from '@angular/router';


@Component({
  selector: 'cosmos-curing-pool',
  templateUrl: './curing-pool-detail.component.html',
  styleUrls: ['./curing-pool-detail.component.scss']
})
export class CuringPoolDetailComponent implements OnInit {

  user: User;
  curingPool: CuringPool | null = null;
  chartData: any = null;
  timeDiff: number = 0;
  
  constructor(
    private curingPoolService: CuringPoolssService,
    private errorService: ErrorService,
    private stateService: StateService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.stateService.currentUser$.subscribe(x => {this.user = x;});

    const url = (this.router.url).split("/");

    this.curingPoolService.findById(url[url.length - 1], "?populate_measurement=name,time,temperature").subscribe({
      next: (response) => this.curingPool = response.body,
      error: (e) => this.errorService.handleError(e),
      complete: () => {
        this.timeDiff = (new Date().getTime() - new Date(this.curingPool.lastseen).getTime())/1000/60/60;
        if (this.curingPool.time.length > 0) {
          this.chartData = {
            measurement: {
              time: this.curingPool.measurement.time,
              temperature: this.curingPool.measurement.temperature
            },
            pool: {
              time: this.curingPool.time,
              temperature: this.curingPool.temp
            }
          }
        } else {
          this.chartData = null;
        }
      }
    })

  }

  unlinkPool(pool) {
    this.curingPoolService.unlink([pool._id, {}]).subscribe({
      next: (response) => {console.log(response); this.curingPool = response.body},
      error: (e) => this.errorService.handleError(e),
      complete: () => {
        this.timeDiff = (new Date().getTime() - new Date(this.curingPool.updatedAt).getTime())/1000/60/60;
        if (this.curingPool.time.length > 0) {
          this.chartData = {
            measurement: {
              time: this.curingPool.measurement.time,
              temperature: this.curingPool.measurement.temperature
            },
            pool: {
              time: this.curingPool.time,
              temperature: this.curingPool.temp
            }
          }
        } else {
          this.chartData = null;
        }
      },
    });
  }

}
