import { LoraMetadata } from "./lora-metadata.model";

export class Device {
  _id?: string;
  name?: string;
  loramac?: string;
  company?: any;
  available?: boolean;
  project?: any;
  stage?: any;
  concrete?: any;
  measurement?: any;
  lastseen?:string;
  battery?: number;
  updatedAt?: Date;
  createdAt?: Date;
  transmission?: LoraMetadata[];
}
